import * as React from "react";
import { FunctionComponent, useContext, useEffect, useRef } from "react";

//MUI Components
import Box from "@mui/material/Box";

//Custom components
import MapContext from "@/components/Map/MapContext";
import Crosshair from "./crosshair";
import { findControl, getDefinedOptions, getEvents } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { ICrosshairRoot } from "@/@types/components/Map/Controls/Crosshair";
import Control from "ol/control/Control";

const CrosshairRoot: FunctionComponent<ICrosshairRoot> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;

  const { id } = props;

  const crosshairRef = useRef(null);

  useEffect(() => {
    if (crosshairRef.current) {
      const options = {
        element: crosshairRef.current,
        target: mapContext.map?.getViewport()
      };

      let allOptions = Object.assign(options, props);
      let definedOptions = getDefinedOptions(allOptions);

      const events = {};

      let control = new Crosshair(definedOptions);

      if (id) {
        control.set("id", id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, Crosshair);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control as Control);
      } else {
        mapContext.initOptions.controls.push(control as Control);
      }

      let olEvents = getEvents(events, props);
      for (let eventName in olEvents) {
        //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, Crosshair);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  return (
    <Box id="crosshair-control" ref={crosshairRef}>
      {props.children}
    </Box>
  );
};

export default CrosshairRoot;
