import LoginPage from "@/views/Auth/LoginPage";
import Registration from "@/views/Auth/RegistrationPage";
import PasswordResetRequestPage from "@/views/Auth/PasswordResetRequestPage";
import PasswordResetPage from "@/views/Auth/PasswordResetPage"

//Types
import { RouteType } from "@/@types/common";
import AdminHomePage from "@/views/Administration/AdminHomePage";
import LogPage from "@/views/Administration/Log/LogPage";
import SettingsPage from "@/views/Administration/Settings/SettingsPage";
import UsersPage from "@/views/Administration/UsersPage/UsersPage";
import GISHomePage from "@/views/GIS/GISHomePage";
import MapPage from "@/views/GIS/MapPage/MapPage";
import GraphPage from "@/views/GraphPage/GraphPage";
import HomePage from "@/views/HomePage/HomePage";
import ProfilePage from "@/views/ProfilePage/ProfilePage";
import TestPage from "@/views/TestPage/TestPage";
import TestView from "@/views/TestPage/TestView";
import PublicHomePage from "@/views/HomePage/PublicHomePage"

const publicRoutes: RouteType[] = [
	{ path: "/login", name: "Login", component: LoginPage },
	//{ path: "/register", name: "Register", component: Registration},
  { path: "/password-reset-request", name: "Password Reset Request", component: PasswordResetRequestPage },
  { path: "/password-reset", name: "Reset Password", component: PasswordResetPage },

  //ex-private routes
  // { path: "/profile", name: "Profile", component: ProfilePage},
  { path: "/", name: "Map", component: PublicHomePage },
  // { path: "/gis/map", name: "Map", component: MapPage },
  // { path: "/gis", name: "GIS Home Page", component: GISHomePage },
	// { path: "/admin/log", name: "Log", component: LogPage },
	// { path: "/admin/users", name: "Map", component: UsersPage },
  // { path: "/admin/settings", name: "Admin Settings Page", component: SettingsPage },
  // { path: "/admin", name: "Admin Home Page", component: AdminHomePage },
  // { path: "/test/:id", name: "Test Page Detail", component: TestView },
  // { path: "/test", name: "Test Page", component: TestPage },
  // { path: "/graphs", name: "Graph Page", component: GraphPage },

	// { path: "/", name: "Home", component: HomePage }
	// { path: "/", name: "Home", component: GISHomePage }
];

export default publicRoutes
