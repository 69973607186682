import * as React from "react";
import { FunctionComponent, useContext } from "react";

//MUI
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";

//Custom components
import SidebarContext from "./SidebarContext/SidebarContext";

//Types
import { ISidebarTabListItem } from "@/@types/components/Map/Controls/Sidebar";
import { SidebarContextType } from "@/@types/components/Map/Controls/Sidebar/SidebarContext";
import { TheaterComedy } from "@mui/icons-material";

const StyledLi = styled('li')(({theme}) => ({
  width: "100%",
  height: 40,
  color: "#FFF",
  fontSize: "12pt",
  overflow: "hidden",
  transition: "all 80ms",
  ":hover" : {
    color: "#FFF",
    backgroundColor: theme.palette.primary.dark
  }
}));

const DisabledLi = styled(StyledLi)(({theme}) => ({
  color: "rgba(255, 255, 255, 0.4)",
  ":hover": {
    backgroundColor: "transparent"
  }
}));

const ActiveLi = styled(StyledLi)(({theme}) => ({
  backgroundColor: theme.palette.primary.main
}));

const StyledA = styled('a')(({theme}) => ({
  display: "block",
  width: "100%",
  height: "100%",
  lineHeight: "40px",
  color: "inherit",
  textDecoration: "none",
  textAlign: "center"
}))

const DisabledA = styled(StyledA)(() => ({
  cursor: "default"
}))

const SidebarTabListItem: FunctionComponent<ISidebarTabListItem> = (props) => {
  const theme = useTheme();

  const { id, icon, disabled, title, onChange } = props;

  const sideBarContext = useContext(SidebarContext) as SidebarContextType;
  // const userContext = useContext(UserContext);

  const handlePaneChange = (id: string): void => {
    if (!disabled) {
      // userContext.setUserSetting({map_sidebar_pane: id});
      sideBarContext.setActivePane(id);

      if (onChange) {
        onChange(id);
      }
    }
  };

  const isActive = sideBarContext.activePane === id;

  let classNames = [];
  if (disabled) {
    classNames.push("disabled");
  }
  if (isActive) {
    classNames.push("active");
  }
  const joinedClassNames = classNames.length > 0 ? classNames.join(" ") : "";

  const LI = disabled ? DisabledLi : isActive ? ActiveLi : StyledLi;
  const A = disabled ? DisabledA : StyledA;

  return (
    <LI className={joinedClassNames} title={title}>
      <A href={"#" + id} role="tab" onClick={(evt) => handlePaneChange(id)}>
        {icon}
      </A>
    </LI>
  );
};

export default SidebarTabListItem;
