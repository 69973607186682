import { helpers } from "@/lib/helpers";

export const localStorageAPI = {
  setValue
, getValue
// , setValueEnc
// , getValueEnc
// , getAllValues
// , removeValueEnc
// , getSecret
// , setSecret
, clearStorage
};

const secret = "uRFZIbtZwfLg";
const xorEncrypt = 0b11011011110101;

function setValue(key: string, page: string, newValue: any) {
  // .getItem returns null if item is not find in localStorage
  // JSON.parse will return null if null is passed to it
  const pageHash = helpers.hash(page);
  const item = localStorage.getItem(key);
  const value = item !== null ? JSON.parse(item) : null;
  if (value === null || Object.keys(value).length === 0) {
    localStorage.setItem(key, JSON.stringify({ [pageHash]: newValue }));
  } else {
    value[pageHash] = newValue;
    localStorage.setItem(key, JSON.stringify(value));
  }
}

function getValue(key:string, page: string, defaultValue = 0) {
  // .getItem returns null if item is not find in localStorage
  // JSON.parse will return null if null is passed to it
  const pageHash = helpers.hash(page);
  const item = localStorage.getItem(key);
  const value = item !== null ? JSON.parse(item) : null;
  if (value === null || Object.keys(value).length === 0 || value[pageHash] === null || value[pageHash] === undefined) {
    setValue(key, page, defaultValue);
    return defaultValue;
  } else {
    return value[pageHash];
  }
}

// function removeValueEnc(key: string, id: number) {
//   const enc = id ^ xorEncrypt;
//   const item = localStorage.getItem(key);
//   const value = item !== null ? JSON.parse(item) : null;
//   if (value === null || Object.keys(value).length === 0) {
//     return;
//   } else {
//     delete value[enc];
//     localStorage.setItem(key, JSON.stringify(value));
//   }
// }

// function setValueEnc(key: string, id: number, newValue: any) {
//   const enc = id ^ xorEncrypt;
//   const item = localStorage.getItem(key);
//   const value = item !== null ? JSON.parse(item) : null;
//   if (value === null || Object.keys(value).length === 0) {
//     localStorage.setItem(key, JSON.stringify({ [enc]: newValue }));
//   } else {
//     value[enc] = newValue;
//     localStorage.setItem(key, JSON.stringify(value));
//   }
// }

// function getValueEnc(key: string, id: number, defaultValue = 0) {
//   // .getItem returns null if item is not find in localStorage
//   // JSON.parse will return null if null is passed to it
//   const enc = id ^ xorEncrypt;
//   const item = localStorage.getItem(key);
//   const value = item !== null ? JSON.parse(item) : null;
//   if (value === null || Object.keys(value).length === 0 || value[enc] === null || value[enc] === undefined) {
//     setValue(key, id, defaultValue);
//     return defaultValue;
//   } else {
//     return value[enc];
//   }
// }

// function getAllValues(key: string) {
//   const item = localStorage.getItem(key);
//   const value = item !== null ? JSON.parse(item) : null;
//   if (value === null) {
//     return null;
//   } else {
//     const retVal = {};
//     Object.keys(value).forEach(k => {
//       retVal[k^xorEncrypt] = value[k];
//     });
//     return retVal;
//   }
// }

// function getSecret(key: string) {
//   const item = localStorage.getItem(key);
//   const value = item !== null ? JSON.parse(item) : null;
//   return value ? (value[secret] ^ xorEncrypt) : null
// }

// function setSecret(key: string, secretValue) {
//   const item = localStorage.getItem(key);
//   const value = item !== null ? JSON.parse(item) : null;

//   localStorage.setItem(key, JSON.stringify(
//     value ?
//       {...value, [secret]: secretValue ^ xorEncrypt }
//     : { [secret]: secretValue ^ xorEncrypt }));
// }

function clearStorage(key: string) {
  localStorage.removeItem(key);
}