import React from "react";
import { useTranslation } from "react-i18next";

//UI Icons
import RefreshIcon from "@mui/icons-material/Refresh";

//Custom Components
import TableHeaderButton from "./TableHeaderButton";
import { ITableHeaderButtonRefreshProps } from "@/@types/ui/Table";

function TableButtonRefresh(props: ITableHeaderButtonRefreshProps) {
  const { t } = useTranslation();

  return (
    <TableHeaderButton variant="contained" startIcon={<RefreshIcon />} {...props}>
      {t("buttons.refresh")}
    </TableHeaderButton>
  );
}

export default TableButtonRefresh;
