import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Types
type NavDrawerLinkProps = React.PropsWithChildren<{
  open: boolean,
  to: string,
  text: string,
  icon: string,
  ttoken: string
}>

const NavDrawerLink = (props: NavDrawerLinkProps) => {
  const { t } = useTranslation();

  type MyNavLinkProps = Omit<NavLinkProps, 'to'>;

  const MyNavLink = React.useMemo(() => React.forwardRef<HTMLAnchorElement, MyNavLinkProps>((navLinkProps, ref) => {
    const { className: previousClasses, ...rest } = navLinkProps;
    const elementClasses = previousClasses?.toString() ?? "";
    return (<NavLink
      {...rest}
      ref={ref}
      to={props.to}
      end
      className={({ isActive }) => (isActive ? elementClasses + " Mui-selected" : elementClasses)}
    />)
  }), [props.to]);

  return (
    <ListItemButton
      component={MyNavLink}
    >
      <ListItemIcon>
        {props.open ? (
          <Icon fontSize="small" color="primary">
            {props.icon}
          </Icon>
        ) : (
          <Tooltip title={t(props.ttoken) as string}>
            <Icon fontSize="small" color="primary">
              {props.icon}
            </Icon>
          </Tooltip>
        )}
      </ListItemIcon>
      <ListItemText primary={t(props.ttoken)} />
    </ListItemButton>
  )
}

export default NavDrawerLink;