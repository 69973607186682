import { TFunction } from "i18next";
import moment from "moment";
import numbro from "numbro";
import { FieldAny, IField, IFieldsCollection, IFieldValidationDateTime, IFieldValidationNumeric, IFieldValidationText } from "../@types/models/model";
import { FieldValue, FieldValuePicker, Record } from "../@types/lib/api/api";
import { FieldValidation, RecordValidation } from "../@types/lib/appValidator";

class appValidator {

  t: TFunction

  constructor(t: TFunction) {
    this.t = t;

    this.validateModel = this.validateModel.bind(this);
    this.validateField = this.validateField.bind(this);
    this.getValue = this.getValue.bind(this);
    this.mergeValidation = this.mergeValidation.bind(this);
    this.checkIfValid = this.checkIfValid.bind(this);
    this.checkIfRecordChanged = this.checkIfRecordChanged.bind(this);
    this.checkIfFieldChanged = this.checkIfFieldChanged.bind(this);

    //Custom validators
    this.checkRegexEmail = this.checkRegexEmail.bind(this);
    this.checkRegexTel = this.checkRegexTel.bind(this);
    this.checkRegexOIB = this.checkRegexOIB.bind(this);
    this.isComplexPassword = this.isComplexPassword.bind(this);
    this.validatePasswordReset = this.validatePasswordReset.bind(this);
    this.validatePasswordChange = this.validatePasswordChange.bind(this);
    this.validatePasswordSet = this.validatePasswordSet.bind(this);

    //Validation messages
    this.msgRequired = this.msgRequired.bind(this);
    this.subSectionRequired = this.subSectionRequired.bind(this);
    this.existingUsername = this.existingUsername.bind(this);
  }

  validateModel(record: Record, fields: IFieldsCollection): RecordValidation {
    const validation: RecordValidation = {};
    fields.forEach((field) => {
      validation[field.source] = this.validateField(record, field);
    });
    // console.log(validation);
    return validation;
  };

  validateField(record: Record, field: FieldAny): FieldValidation {
    const fieldValidation: FieldValidation = {valid: true, msg: ""};
    const isRequired = field.validation && field.validation.required;
    const isEmail =
        field.validation &&
        field.validation.hasOwnProperty("regex") &&
        (field.validation as IFieldValidationText).regex === "email";
    const isTelefon =
        field.validation &&
        field.validation.hasOwnProperty("regex") &&
        (field.validation as IFieldValidationText).regex === "tel";
    const isOIB =
        field.validation &&
        field.validation.hasOwnProperty("regex") &&
        (field.validation as IFieldValidationText).regex === "oib";
    const hasMaxDate =
        field.type === "date" &&
        field.validation &&
        field.validation.hasOwnProperty("maxDate");
    const isDateAfter =
        (field.type === "date" || field.type === "datetime") &&
        field.validation &&
        field.validation.hasOwnProperty("isAfter");
    const hasCharLimit =
        field.validation && field.validation.hasOwnProperty("maxLength");
    const maxLength = hasCharLimit ? (field.validation as IFieldValidationText).maxLength : null;
    const hasMinCharsRequired =
        field.validation && field.validation.hasOwnProperty("minLength");
    const value =
        record && record.hasOwnProperty(field.source)
            ? record[field.source]
            : null;
    const minLength = hasMinCharsRequired ? (field.validation as IFieldValidationText).minLength : null;
    let hasValue = value !== undefined && value !== null && value !== "";
    if (Array.isArray(value) && value.length === 0) {
      hasValue = false;
    }

    const hasMinValue = field.validation && field.validation.hasOwnProperty("minValue");
    const minValue = hasMinValue ? (field.validation as IFieldValidationNumeric).minValue : null;
    const hasMaxValue = field.validation && field.validation.hasOwnProperty("maxValue");
    const maxValue = hasMaxValue ? (field.validation as IFieldValidationNumeric).maxValue : null;

    if (isRequired && !hasValue) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t("validation.required");
    } else if (isEmail && hasValue && this.checkRegexEmail(value as string) === false) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t("validation.emailformat");
    } else if (isTelefon && hasValue && this.checkRegexTel(value as string) === false) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t("validation.telformat");
    } else if (isOIB && hasValue && this.checkRegexOIB(value as string) === false) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t("validation.oibformat");
    } else if (hasCharLimit) {
      if (hasValue && maxLength !== null && maxLength !== undefined) {
        if ( (value as string).length > maxLength) {
          fieldValidation.valid = false;
          fieldValidation.msg = this.t("validation.max_char", {max_char: maxLength.toString(), curr_char: (value as string).length});
        }
      }
    } else if (hasMinCharsRequired) {
      if (hasValue && minLength !== null && minLength !== undefined) {
        if ((value as string).length < minLength) {
          fieldValidation.valid = false;
          fieldValidation.msg = this.t("validation.min_char", {min_char: minLength.toString(), curr_char: (value as string).length});
        }
      }
    } else if ((hasMinValue || hasMaxValue) && hasValue) {
      const floatValue = typeof value === "string" ? parseFloat(value.replace(',', '.')) : value;
      if (hasMinValue && minValue !== null && minValue !== undefined && floatValue !== null) {
        if (floatValue < minValue) {
          fieldValidation.valid = false;
          fieldValidation.msg = this.t("validation.min_value", {min_value: minValue.toString()});
        }
      }
      if (hasMaxValue && maxValue !== null && maxValue !== undefined && floatValue !== null) {
        if (floatValue > maxValue) {
          fieldValidation.valid = false;
          fieldValidation.msg = this.t("validation.max_value", {max_value: maxValue.toString()});
        }
      }
    } else if (hasMaxDate) {
      const maxDate = (field.validation as IFieldValidationDateTime).maxDate;
      if (maxDate !== undefined) {
        if (moment(value as string).isAfter(moment(new Date()).add(maxDate, 'days'), 'day')) {
          fieldValidation.valid = false;
          fieldValidation.msg = this.t("validation.max_date", {date: moment(new Date()).add(maxDate, 'days').format("DD.MM.YYYY.")});
        }
      }
    } else if (isDateAfter && hasValue) {
      const isAfterDefinition = (field.validation as IFieldValidationDateTime).isAfter;
      if (isAfterDefinition !== undefined) {
        const otherFieldId = isAfterDefinition.source;
        const otherValue = record[otherFieldId];
        const otherHasValue = otherValue !== undefined && otherValue !== null && otherValue !== ""
        const oherFieldName = this.t(isAfterDefinition.ttoken);
        if (otherHasValue) {
          if (!moment(value as string).isAfter(moment(otherValue as string))) {
            fieldValidation.valid = false;
            fieldValidation.msg = this.t("validation.isafter_date", {field: oherFieldName});
          }
        }

      }

    }

    return fieldValidation;
  };

  mergeValidation(
      modelValidation: RecordValidation,
      customValidation: RecordValidation
  ): RecordValidation {
    let mergedValidation = modelValidation || {};
    if (customValidation !== undefined && customValidation !== null) {
      Object.keys(customValidation).forEach(key => {
        mergedValidation[key] = customValidation[key];
        // if (mergedValidation.hasOwnProperty(key)) {
        //   mergedValidation[key] = customValidation[key];
        // } else {
        //   mergedValidation.push(key, customValidation[key]);
        // }
      });
    }
    return mergedValidation;
  };

  checkIfValid(validation: RecordValidation): boolean {
    const validatonFailed = Object.keys(validation)
        .map(key => validation[key].valid)
        .some(x => x === false);
    const isValid = !validatonFailed;
    return isValid;
  };

  checkIfRecordChanged(record: Record, originalRecord: Record): boolean {
    const isChanged = Object.keys(record).some(key =>
        this.checkIfFieldChanged(record[key], originalRecord[key])
    );
    return isChanged;
  };

  checkIfFieldChanged(a: FieldValue, b: FieldValue): boolean {
    if (a === null && b === null) {
      return false;
    } else if (moment.isMoment(a) && moment.isMoment(b)) {
      if (!a.isValid() && !b.isValid()) {
        return false;
      } else {
        return !a.isSame(b);
      }
    } else {
      return this.getValue(a) !== this.getValue(b);
    }
  };

  getValue(x: FieldValue) {
    if (x === null || x === undefined) {
      return null;
    } else if (moment.isMoment(x)) {
      return x.isValid() ? x : null;
    } else if (numbro.isNumbro(x)) {
      return x.value();
    } else if (x.hasOwnProperty("value")) {
      return (x as FieldValuePicker).value; //option
    } else {
      return x; //other
    }
  };

  returnFormValue(record: Record, k: string) {
    const key = k as keyof Record;
    if (record !== null && record.hasOwnProperty(key)) {
      const val = record[key];
      if (val !== null && val !== undefined) {
        if (typeof val === "object" && val.hasOwnProperty("value")) {
          return (val as FieldValuePicker).value;
        } else {
          return val;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

//Validation messages
  msgRequired(): FieldValidation {
    return {
      valid: false,
      msg: this.t("validation.required")
    };
  }

  subSectionRequired(): FieldValidation {
    return {
      valid: false,
      msg: this.t("validation.subSection_required")
    };
  }

  existingUsername(): FieldValidation {
    return {
      valid: false,
      msg: this.t("validation.existing_username")
    }
  }

//Custom Validators

  checkRegexEmail(value: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const matches = value.match(re);
    return matches != null ? true : false;
  };

  checkRegexTel(value: string): boolean {
    const re = /^\+[0-9]{11,15}$/;
    const matches = value.match(re);
    return matches != null ? true : false;
  };

  checkRegexOIB(value: string): boolean {
    const re = /^[0-9]{11}$/;
    const matches = value.match(re);
    return matches != null ? true : false;
  };

  isComplexPassword(password: string): boolean {
    if (password.length < 8) {
      return false;
    }

    var matchedCase = new Array();
    matchedCase.push("[A-Z]"); // Uppercase Letters
    matchedCase.push("[0-9]"); // Numbers
    matchedCase.push("[a-z]"); // Lowercase Letters

    var ctr = 0;
    for (var i = 0; i < matchedCase.length; i++) {
      if (new RegExp(matchedCase[i]).test(password)) {
        ctr++;
      }
    }

    return ctr == 3 ? true : false;
  };

  validatePasswordReset(record: Record): RecordValidation {
    const fNewPass = "password_new";
    const fNewPassConfirm = "password_new_confirm";

    const newPass =
        record && record.hasOwnProperty(fNewPass) ? record[fNewPass] : null;
    const newPassConfirm =
        record && record.hasOwnProperty(fNewPassConfirm)
            ? record[fNewPassConfirm]
            : null;

    let validation: RecordValidation = {};

    if (newPass && typeof newPass === "string" && newPass.length > 0 && this.isComplexPassword(newPass) === false) {
      validation[fNewPass] = {
        valid: false,
        msg: this.t("validation.requirementPassword")
      };
    }

    if (newPass !== newPassConfirm) {
      validation[fNewPassConfirm] = {
        valid: false,
        msg: this.t("validation.paswordMismatch")
      };
    }

    return validation;
  };

  validatePasswordChange(record: Record): RecordValidation {
    const fOldPass = "password";
    const fNewPass = "password_new";
    const fNewPassConfirm = "password_new_confirm";

    const oldPass =
        record && record.hasOwnProperty(fOldPass) ? record[fOldPass] : null;
    const newPass =
        record && record.hasOwnProperty(fNewPass) ? record[fNewPass] : null;
    const newPassConfirm =
        record && record.hasOwnProperty(fNewPassConfirm)
            ? record[fNewPassConfirm]
            : null;

    let validation: RecordValidation = {};
    if (newPass && typeof newPass === "string" && newPass.length > 0 && this.isComplexPassword(newPass) === false) {
      validation[fNewPass] = {
        valid: false,
        msg: this.t("validation.requirementPassword")
      };
    }

    if (newPass && typeof newPass === "string" && newPass.length > 0 && oldPass === newPass) {
      validation[fNewPass] = {
        valid: false,
        msg: this.t("validation.sameNewPassword")
      };
    }

    if (newPass !== newPassConfirm) {
      validation[fNewPassConfirm] = {
        valid: false,
        msg: this.t("validation.paswordMismatch")
      };
    }

    return validation;
  };

  validatePasswordSet(record: Record, t: TFunction): RecordValidation {
    const fPass = "password";
    const fPassConfirm = "password_confirm" as keyof Record;

    const pass =
        record && record.hasOwnProperty(fPass) ? record[fPass] : null;
    const passConfirm =
        record && record.hasOwnProperty(fPassConfirm)
            ? record[fPassConfirm]
            : null;

    let validation: RecordValidation = {};

    if (pass && typeof pass === "string" && pass.length > 0 && this.isComplexPassword(pass) === false) {
      validation[fPass] = {
        valid: false,
        //msg: "Lozinka treba sadržavati barem 8 znakova, 1 malo slovo, 1 veliko slovo i 1 znamenku"
        msg: this.t("validation.requirementPassword")
      };
    }

    if (pass !== passConfirm) {
      validation[fPassConfirm] = {
        valid: false,
        //msg: "Potvrda lozinke se ne podudara s novom lozinkom!"
        msg: this.t("validation.passwordMismatch")
      };
    }

    return validation;
  };

}

export default appValidator;
