import * as React from "react";
import { Fragment, FunctionComponent } from "react";

//MUI
import Dialog from "@mui/material/Dialog";

//Custom Components
import DraggableDialog from "@/ui/Dialog/DraggableDialog";

//Types
import { IDraggableDialog } from "@/@types/ui/Dialog";

const DynamicDialog: FunctionComponent<IDraggableDialog> = (props) => {
  const { fixedHeight, children, maxWidth } = props;

  return (
    <Fragment>
      <DraggableDialog
        sx={{ display: { xs: "none", md: "block" } }}
        open={true}
        fullScreen={false}
        fixedHeight={fixedHeight}
        maxWidth={maxWidth || "lg"}
      >
        {children}
      </DraggableDialog>
      <Dialog sx={{ display: { xl: "none", lg: "block" } }} open={true} fullScreen={true}>
        {children}
      </Dialog>
    </Fragment>
  );
};

export default DynamicDialog;
