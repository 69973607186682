import React from "react";

//MUI
import Typography from "@mui/material/Typography";

//Types
import { ITableHeadingProps } from "@/@types/ui/Table";

function TableHeading(props: ITableHeadingProps) {
  const { children } = props;
  return (
    <Typography
      align="left"
      variant="h5"
      component="h3"
      sx={{
        mx: 2
      }}
    >
      {children}
    </Typography>
  );
}

export default TableHeading;
