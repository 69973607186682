import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI Core
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material";

// Custom Components
import GridContainer from "@/ui/Grid/GridContainer";
import GridItem from "@/ui/Grid/GridItem";
import BarChart from "@/ui/Charts/BarChart";
import LineGraph from "@/ui/Charts/LineGraph";

// Dummy Data
import { graphSeries } from "@/../dummyData"

function Dashboard() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card style={{ height: "100%" }}>
          <CardContent>
            {true || false ? <BarChart color={theme.palette.primary.main} series={graphSeries} /> : null}
          </CardContent>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card style={{ height: "100%" }}>
          <CardContent>
            {true || false ? <LineGraph color={theme.palette.primary.main} series={graphSeries} /> : null}
          </CardContent>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default Dashboard;
