import * as React from "react";
import { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI Core Components
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";

//Custom Components
import { GridContainer, GridItem } from "@/ui/Grid";

//Types
import { IMeasuresPane } from "@/@types/components/MapSidebarPanes";

const LINE = "LineString";
const POLY = "Polygon";
const PT = "Point";

const MeasuresPane: FunctionComponent<IMeasuresPane> = (props) => {
  const { t } = useTranslation();
  const { changeDrawType, handleEraseMeasurements, drawType } = props;

  return (
    <Fragment>
      <GridContainer style={{ marginBottom: "10px" }}>
        <GridItem>
          <Tooltip title={t("map:measure.tooltips.line") as string}>
            <Button
              value="length"
              color="primary"
              onClick={() => changeDrawType(LINE)}
              variant={drawType === LINE ? "contained" : "outlined"}
              startIcon={<i className="fas fa-ruler-horizontal" />}
            >
              {t("map:measure.buttons.line")}
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem>
          <Tooltip title={t("map:measure.tooltips.area") as string}>
            <Button
              value="area"
              color="primary"
              onClick={() => changeDrawType(POLY)}
              variant={drawType === POLY ? "contained" : "outlined"}
              startIcon={<i className="fas fa-vector-square" />}
            >
              {t("map:measure.buttons.area")}
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem>
          <Tooltip title={t("map:measure.tooltips.point") as string}>
            <Button
              value="point"
              color="primary"
              onClick={() => changeDrawType(PT)}
              variant={drawType === PT ? "contained" : "outlined"}
              startIcon={<i className="fas fa-map-marker-alt" />}
            >
              {t("map:measure.buttons.point")}
            </Button>
          </Tooltip>
        </GridItem>
      </GridContainer>
      <Divider sx={{ mb: 1 }} />
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleEraseMeasurements()}
        startIcon={<i className="fas fa-trash" />}
      >
        {t("map:measure.buttons.erase")}
      </Button>
    </Fragment>
  );
};

export default MeasuresPane;
