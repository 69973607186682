import React from "react";
import Chart from "react-apexcharts";

function LineColumn (props: any) {
    const { series, color } = props

    const options = {
        chart: {
          type: 'line',
        },
        colors: [color],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Graph example',
          align: 'center'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        }
    }

  return (
    <Chart
      // @ts-ignore
      options={options}
      series={series}
      height="300px"
    />
  );
}


export default LineColumn