import * as React from "react";
import { FunctionComponent, useContext, useEffect, useState } from "react";

//OpenLayers
import { getDistance } from "ol/sphere";
import { transform } from "ol/proj";
import Map from "ol/Map";
import View from "ol/View";

//Custom components
import MapContext from "@/components/Map/MapContext";
import ScaleRatioRoot from "@/components/Map/Controls/ScaleRatio/ScaleRatioRoot";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IScaleRatioControl } from "@/@types/components/Map/Controls/ScaleRatio";
import { MapEvent } from "ol";


const ScaleRatioControl: FunctionComponent<IScaleRatioControl> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;
  const [fieldValue, setFieldValue] = useState<string>("");

  const { ppi } = props;

  useEffect(() => {
    if (mapContext && mapContext.map) {
      showScale();
    }
  }, [mapContext]);

  useEffect(() => {
    if (mapContext.map) {
      mapContext.map.on("moveend", handleMoveEnd);
    }

    return () => {
      if (mapContext.map) {
        mapContext.map.un("moveend", handleMoveEnd);
      }
    };
  }, [mapContext.map]);


  const handleFieldValue = (value: string): void => {
    setFieldValue(value);
  };

  const handleMoveEnd = (evt: MapEvent) => {
    const view = evt.map.getView();
    if (view) {
      const newZoom =  evt.map.getView().getZoom();
      showScale();
    }
  };

  const getD = (map: Map): number | null => {
    const view = map.getView();
    const proj = view.getProjection();
    const center = view.getCenter();
    //@ts-ignore TODO: Type 'undefined' is not assignable to type 'number[]', function does not account for undefined
    let px = map.getPixelFromCoordinate(center);
    if (px !== null) {
      px[1] += 1;
      let coord = map.getCoordinateFromPixel(px);
      let d = getDistance(
        //@ts-ignore TODO: Type 'undefined' is not assignable to type 'number[]', function does not account for undefined
        transform(center, proj, "EPSG:4326"),
        transform(coord, proj, "EPSG:4326")
      );
      d *= ppi / 0.254;
      return d;
    } else {
      return null;
    }
  };

  const showScale = (): void => {
    if (mapContext.map) {
      const d = getD(mapContext.map);
      if (d !== null) {
        handleFieldValue(formatScale(d * 10));
      } else {
        handleFieldValue(formatScale(null));
      }
    }
  };

  const formatScale = (d: number | null): string => {
    if (d === null) {
      return "1:???";
    }
    else if (d > 100) {
      d = Math.round(d / 100) * 100;
    } else {
      d = Math.round(d);
    }
    return "1:" + d.toString();
  };

  const setScale = (value: any): void => {
    if (mapContext.map && value) {
      const view = mapContext.map.getView();
      let fac;
      if (value[1] === ":") {
        fac = value.split(":")[1];
        fac = parseInt(fac) / 10;
      } else {
        return setScale(formatScale(value));
      }
      const d = getD(mapContext.map);
      //@ts-ignore TODO: object is possibly undefined
      view.setResolution(view.getResolution() * (fac / d));
    }
    showScale();
  };

  const handleEnterKey = (evt: React.KeyboardEvent<HTMLInputElement>): void => {
    if (evt.key === "Enter") {
      //@ts-ignore TODO: value does not exist on Event
      setScale(evt.target.value);
    }
  };

  return (
    <ScaleRatioRoot>
      <input
        value={fieldValue}
        onChange={(evt) => handleFieldValue(evt.target.value)}
        onKeyDown={(evt) => handleEnterKey(evt)}
      />
    </ScaleRatioRoot>
  );
};

export default ScaleRatioControl;

