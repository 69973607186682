import React, { useContext, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";

//MUI
import { Box, Toolbar, DialogActions } from "@mui/material";

//Custom Components
import GridContainer from "@/ui/Grid/GridContainer";
import FormContent from "@/components/FormContent";
import DraggableDialog from "../../../ui/Dialog/DraggableDialog";
import DialogHeader from "../../../ui/Dialog/DialogHeader";
import DialogToolbarHeading from "../../../ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "../../../ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "../../../ui/Toolbar/ToolbarFillContent";
import DialogBody from "../../../ui/Dialog/DialogBody";
import DialogActionButtonConfirm from "../../../ui/Dialog/ActionButtons/DialogActionButtonConfirm";
import DialogActionButtonCancel from "../../../ui/Dialog/ActionButtons/DialogActionButtonCancel";
import useAuthFormController from "@/components/useAuthFormController";
import appValidator from '@/lib/appValidator';
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";

import DialogContext from "../../../ui/DialogContext/DialogContext";
import { userService } from "@/services/userService";

//Types
import { FormControllerProps } from "@/@types/components/formController";
import { SnackbarContextType } from "@/@types/ui/SnackbarContext";

function SetPasswordDialog(props: any) {
  const { onClose, dc, form, mode, id } = props;
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();
  const validator = new appValidator(t);
  const [validation, setValidation] = useState({})
  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;

  const { record, fields, onFieldChange } = useAuthFormController(
    props as FormControllerProps
  );

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleChange = (evt: SyntheticEvent) => {
    const validation = validator.validateModel(record, fields);
    const passwordValidation = validator.validatePasswordSet(record, t)
    const finalValidation = validator.mergeValidation(validation, passwordValidation);
    const isValid = validator.checkIfValid(finalValidation);
    setValidation(finalValidation)

    if(!isValid) return

    userService.setPassword(id, record)
    .then(() => {
      close({ success: false });
      if (snackbarContext !== null) {
        snackbarContext.showNotification(t("messages.password_set_success"), "success");
      }
    })
    .catch(() => {
      if (snackbarContext !== null) {
        snackbarContext.showNotification(t("error.set_password"), "error");
      }
    })

  };

  const handleClose = (evt: SyntheticEvent) => {
    close({ success: false });
  };
  
  const close = (result: {success: boolean}) => {
    if (onClose) {
      onClose(result);
    }

    dialogContext.hideDialog();
  };

  return (
    <DraggableDialog open={true} maxWidth={"xs"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="regular" disableGutters={true}>
          <DialogToolbarHeading>{t("titles.password_set")}</DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <form>
            <GridContainer>
              <FormContent
                record={record}
                validation={validation}
                fields={fields}
                mode="insert"
                fieldNames={["password", "password_confirm"]}
                onFieldChange={handleFieldChange}
                columns={1}
              />
            </GridContainer>
          </form>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
        <DialogActionButtonConfirm variant="contained" onClick={handleChange} />
      </DialogActions>

    </DraggableDialog>
  )
}

export default SetPasswordDialog