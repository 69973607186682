import * as React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

//MUI
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

// MUI Icons
import LoginIcon from "@mui/icons-material/Login";

//Custom Components
import { appHeaderTheme } from "@/components/ThemeContext/ThemeProvider";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import ThemeSwitch from "@/ui/Header/ThemeSwitch";
// import LanguageSelector from "@/ui/Header/LanguageSelector";
import HeaderIconButton from "@/ui/Header/HeaderIconButton";

const PublicHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const toolbarStyle = {
    "& > button": {
      mx: 1
    },
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    // borderBottom: "2px",
    borderStyle: "solid",
    borderColor: "primary.main"
  };
  
  const isHome = location.pathname == '/'

  return (
    <div id="header" style={{ flexGrow: 1 }}>
      <MuiThemeProvider
        theme={(outerTheme) => ({
          ...outerTheme,
          palette: {
            ...outerTheme.palette,
            secondary: appHeaderTheme.palette.secondary
          }
        })}
      >
        <AppBar position="fixed" color="secondary">
          <Toolbar variant="dense" sx={toolbarStyle}>
            <ToolbarFillContent>
              <Typography
                variant="h6"
                component="h2"
                sx={{ color: "#fff", fontSize: "1.15rem", flexGrow: 1, textAlign: "center", justifyContent: "center" }}
              >
                <NavLink
                  to="/"
                  style={{
                    color: "#fff",
                    textDecoration: "none"
                  }}
                >
                  {t("app_title")}
                </NavLink>
              </Typography>
            </ToolbarFillContent>
            <ThemeSwitch />
            {/* <LanguageSelector /> */}
            {isHome ? (
              <HeaderIconButton
                onClick={() => {
                  navigate("/login");
                }}
                desc="login"
              >
                <LoginIcon />
              </HeaderIconButton>
            ) : (
              null
            )}
          </Toolbar>
        </AppBar>
      </MuiThemeProvider>
    </div>
  );
};

export default PublicHeader;
