import { IModel } from "../@types/models/model";
import { coreFields } from "./helpers/coreFields";

const Grading: IModel = {
  title: "Grading",
  apiPath: "data/poi/grading",
  forms: {
    "default": {
      fields: ["category_id", "ocjena", "reason_id", "komentar"]
    },
  },
  listViews: {
    "default": {
      fields: ["id", "poi_id", "poi_naziv", "category_id", "reason_id", "ocjena", "komentar", "created_by", "created_on"],
      hidden: ["id", "poi_id"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.created_by,
    coreFields.created_on,
    {
      title: "POI ID",
      source: "poi_id",
      ttoken: "grading.poi_id",
      type: "text",
      sort: true,
      filter: true
    },
    {
      title: "POI naziv",
      source: "poi_naziv",
      ttoken: "grading.poi_naziv",
      type: "text",
      sort: true,
      filter: true
    },
    {
      title: "Ocjena",
      source: "ocjena",
      ttoken: "grading.ocjena",
      type: "numeric",
      sort: true,
      filter: true
    },
    {
      title: "Komentar",
      source: "komentar",
      ttoken: "grading.komentar",
      type: "text",
      sort: true,
      filter: true
      //   validation: {
      //     required: true
      //   }
    },
    {
      title: "ID kategorije",
      source: "category_id",
      ttoken: "grading.steps.ranjiva_skupina",
      type: "radio",
      items: {
        labels: ["grading.rs1.title", "grading.rs2.title", "grading.rs3.title", "grading.rs4.title", "grading.rs5.title"],
        values: [1, 2, 3, 4, 5]
      },
      translate: true
    },

    {
      title: "ID razloga",
      source: "reason_id",
      ttoken: "grading.element_pristupacnosti",
      type: "radio",
      items: {
        labels: [
          'grading.rs1.ep1',
          'grading.rs1.ep2',
          'grading.rs1.ep3',
          'grading.rs1.ep4',
          'grading.rs1.ep5',
          'grading.rs1.ep6',
          'grading.rs2.ep1',
          'grading.rs2.ep2',
          'grading.rs2.ep3',
          'grading.rs2.ep4',
          'grading.rs3.ep1',
          'grading.rs3.ep2',
          'grading.rs3.ep3',
          'grading.rs4.ep1',
          'grading.rs4.ep2',
          'grading.rs4.ep3',
          'grading.rs4.ep4',
          'grading.rs5.ep1',
          'grading.rs5.ep2',
          'grading.rs5.ep3',
          'grading.rs5.ep4'
        ],
        values: [
          11,
          12,
          13,
          14,
          15,
          16,
          21,
          22,
          23,
          24,
          31,
          32,
          33,
          41,
          42,
          43,
          44,
          51,
          52,
          53,
          54
        ]
      }
    },
  ]
}

export default Grading;

