import * as React from "react";

import AdminPage from "@/ui/AppPage/AdminPage";
import AppPage from "@/ui/AppPage/AppPage";
import UsersTable from "./UsersTable";

import Box from '@mui/material/Box';

import { Permissions } from "@/lib/permissions";

function UsersPage() {
  return (
    <AppPage needPermission={[Permissions.Administration]}>
      <Box m={0} style={{ height: "100%" }}>
        <UsersTable />
      </Box>
    </AppPage>
  );
}

export default UsersPage;
