import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

// d.ts
import { ResetPasswordToken } from "@/@types/views/Auth/passwordreset";
import { IDataController } from "@/@types/lib/dataController"

//Material-UI Core Components
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

//Custom Components
import PublicPage from "@/ui/PublicPage/PublicPage";
import Loader from "@/ui/Loader/Loader";
import PasswordResetForm from "@/views/Auth/PasswordResetForm";

import model from "@/models/user";
import dataController from "@/lib/dataController";

import { authService } from "@/services/authService";

function PasswordResetPage() {
  const [token, setToken] = useState<ResetPasswordToken>({ ok: null, email: null, username: null });
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const dc: IDataController = new dataController(model);

  useEffect(() => {
    if (paramToken) {
      setIsLoading(true);
      authService
        .check(paramToken)
        .then((response) => {
          setToken({
            ok: true,
            email: response.email,
            username: response.username
          });
        })
        .catch((error) => {
          setToken({
            ok: false
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const getQueryToken = () => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");
    return token;
  };

  const paramToken = getQueryToken();

  let defaultValues = {};
  if (token.ok) {
    defaultValues = {
      email: token.username,
      passwordreset_token: paramToken
    };
  }

  return (
    <PublicPage>
      <Box m={2}>
        <Container maxWidth={"sm"}>
          {token.ok === null ? (
            <Card>
              <CardContent>
                <Typography variant="body1">{t("messages.please_wait")}</Typography>
              </CardContent>
            </Card>
          ) : token.ok ? (
            <PasswordResetForm
              dc={dc}
              form="password_reset"
              mode="update"
              defaultValues={defaultValues}
              onClose={() => {}}
            />
          ) : (
            <Card>
              <CardContent>
                <Typography variant="body1">{t("messages.passwordreset_token_unknown")}</Typography>
              </CardContent>
            </Card>
          )}
        </Container>
        <Loader open={isLoading} />
      </Box>
    </PublicPage>
  );
}

export default PasswordResetPage;
