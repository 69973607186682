import React from "react";
import { grey, lightBlue, lightGreen, orange, purple, red, yellow } from "@mui/material/colors";

export const helpers = {
	hash,
  retrieveIconColor
};


function hash(str: string) {
	// one of primes [31, 33, 37, 39, and 41] will produce less than 7 collisions if
	// 		we take over 50000 English words
  const H   = 37;
  let total = 0;

  for (var i = 0; i < str.length; i++) {
    total += H * total + str.charCodeAt(i);
  }
  total %= 1002569; // arbitrary prime number -- so we do not get some huge values

  return parseInt(String(total));
}

function retrieveIconColor(icon: string): string | undefined {
  switch(icon){
    case "drafts":
      return yellow["700"];
    case "send":
      return lightBlue["500"];
    case "hourglass_empty":
      return grey["500"];
    case "thumb_up":
      return lightGreen["500"];
    case "thumb_down":
      return red["500"];
    case "help":
      return orange["500"];
    case "assignment_turned_in":
      return lightGreen["500"];
    case "commute":
      return purple["500"];
    case "home":
      return lightGreen["500"];
    case "archive":
      return orange["500"];
    case "warning":
      return red["500"];
    case "remove_circle":
      return red["500"];
    default:
      return undefined;
  }
}
