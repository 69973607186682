import * as React from "react";

import AppPage from "@/ui/AppPage/AppPage";
import GradingTable from './GradingTable'

import Box from '@mui/material/Box';

import { Permissions } from "@/lib/permissions";

function GradingPage() {
  return (
    <AppPage needPermission={[Permissions.Administration]}>
      <Box m={0} style={{ height: "100%" }}>
        <GradingTable />
      </Box>
    </AppPage>
  );
}

export default GradingPage;
