import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from "i18next-http-backend";

import numbro from "numbro";
import moment from "moment";
import "moment/locale/hr";

i18n
  .use(HttpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    ns: [ "translation", "map"],
    defaultNS: "translation",
    // lng: "hr",
    fallbackLng: "hr",
    detection: {
        order: ['localStorage'],
        lookupLocalStorage: 'i18nextLng'
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    react: {
      useSuspense: false,
    },
  });

i18n.on("languageChanged", function (lng) {
  moment.locale(lng);
  numbro.setLanguage(lng);
});

export default i18n;
