import * as React from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI Icons
import SaveIcon from "@mui/icons-material/Save";

//Custom Components
import DialogActionButton from "@/ui/Dialog/DialogActionButton";

//Types
import { IDialogActionButtonDetail } from "@/@types/ui/Dialog";

const DialogActionButtonSave: FunctionComponent<IDialogActionButtonDetail> = (props) => {
  const { t } = useTranslation();

  return (
    <DialogActionButton startIcon={<SaveIcon />} {...props} ariaLabel="Save" ariaControls="Save">
      {t("buttons.save")}
    </DialogActionButton>
  );
};

export default DialogActionButtonSave;
