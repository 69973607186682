import HomePage from "@/views/HomePage/HomePage";
import GISHomePage from "@/views/GIS/GISHomePage";
import MapPage from "@/views/GIS/MapPage/MapPage";
import ProfilePage from "@/views/ProfilePage/ProfilePage";
import CommentsPage from "@/views/CommentsPage/CommentsPage"
import GradingPage from "@/views/Grading/GradingPage"
import AdminHomePage from "@/views/Administration/AdminHomePage";
import SettingsPage from "@/views/Administration/Settings/SettingsPage";
import UsersPage from "@/views/Administration/UsersPage/UsersPage";
import LogPage from "@/views/Administration/Log/LogPage";
import TestPage from "@/views/TestPage/TestPage";
import TestView from "@/views/TestPage/TestView";
import GraphPage from "@/views/GraphPage/GraphPage";

//Types
import { RouteType } from "@/@types/common";

const privateRoutes: RouteType[] = [
  { path: "/profile", name: "Profile", component: ProfilePage},
  { path: "/map", name: "Map", component: MapPage },
  { path: "/users", name: "Users", component: UsersPage },
  // { path: "/comments", name: "Comments", component: CommentsPage },
  { path: "/grading", name: "Grading", component: GradingPage },
  // { path: "/gis/map", name: "Map", component: MapPage },
  // { path: "/gis", name: "GIS Home Page", component: GISHomePage },
	// { path: "/admin/log", name: "Log", component: LogPage },
	// { path: "/admin/users", name: "Map", component: UsersPage },
  // { path: "/admin/settings", name: "Admin Settings Page", component: SettingsPage },
  // { path: "/admin", name: "Admin Home Page", component: AdminHomePage },
  // { path: "/test/:id", name: "Test Page Detail", component: TestView },
  // { path: "/test", name: "Test Page", component: TestPage },
  // { path: "/graphs", name: "Graph Page", component: GraphPage },

	{ path: "/", name: "Home", component: HomePage }
];

export default privateRoutes
