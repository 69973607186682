const moment = require("moment");

const sampleData = [
  {
    id: 1,
    related_animal_id: 2,
    desc: "john",
    date: moment.utc("2022-02-16"),
    date_time: moment.utc("2022-02-16 16:24:00"),
    wholenum: 5,
    float: 9.99,
    amount: 75.02,
    allowed: true,
    illegal: true,
    features: 7,
    regions: [2, 6],
    species: 3,
    colors: [1, 2],
    kingdom: 4,
    names: ["pirate", "robber", "builder"],
    related_animal: "Sam",
    email: "john@gmail.com"
  },
  {
    id: 2,
    related_animal_id: 1,
    desc: "sam",
    date: moment.utc("2022-02-18"),
    date_time: moment.utc("2022-02-18 13:24:00"),
    wholenum: 10,
    float: 19.99,
    amount: 26.2,
    allowed: true,
    illegal: false,
    features: 3,
    regions: [1, 7],
    species: 2,
    colors: [2, 1],
    kingdom: 3,
    names: ["seven", "eight", "nine"],
    related_animal: "John",
    email: "sam@outlook.com"
  },
  {
    id: 3,
    related_animal_id: 4,
    desc: "Dan",
    date: moment.utc("2022-02-20"),
    date_time: moment.utc("2022-02-20 20:20:00"),
    wholenum: 49,
    float: 259.99,
    amount: 95.0,
    allowed: true,
    illegal: false,
    features: 2,
    regions: [4, 6],
    species: 5,
    colors: [4, 6, 3],
    kingdom: 1,
    names: ["some", "thing", "here"],
    related_animal: "Nina",
    email: "dan@gmail.com"
  },
  {
    id: 4,
    related_animal_id: 3,
    desc: "Nina",
    date: moment.utc("2022-02-21"),
    date_time: moment.utc("2022-02-21 13:24:00"),
    wholenum: 651,
    float: 650.99,
    amount: 125.72,
    allowed: true,
    illegal: true,
    features: 5,
    regions: [1, 3, 7],
    species: 7,
    colors: [5, 6, 1],
    kingdom: 5,
    names: ["different", "name", "there"],
    related_animal: "Dan",
    email: "nina@yahoo.com"
  }
];

const colors = [
  { label: "test_page.colors.brown", value: 1 },
  { label: "test_page.colors.black", value: 2 },
  { label: "test_page.colors.green", value: 3 },
  { label: "test_page.colors.purple", value: 4 },
  { label: "test_page.colors.red", value: 5 },
  { label: "test_page.colors.white", value: 6 },
  { label: "test_page.colors.cyan", value: 7 }
];

const barGraphData = {
  "01": 13,
  "02": 0,
  "03": 2,
  "04": 6,
  "05": 9,
  "06": 6,
  "07": 5,
  "08": 5,
  "09": 6,
  "10": 10,
  "11": 11,
  "12": 20
};

const pieGraphData = {
  sampleCount: 47,
  exampleCount: 34,
  ounceCount: 14,
  nullCount: 0,
  oneCount: 1
};

const mapDefaults = [
  {
    default_extent: [2049316, 5724467, 2050352, 5726149],
    initial_view_center: [2049834, 5725308],
    initial_view_zoom: 16
  }
];

const baseLayersData = [
  {
    id: 1,
    code: "osm",
    ttoken: "map:layer.osm",
    layer_type: 20,
    url: null,
    layer: null,
    projection: "EPSG:3765",
    visible: true,
    preview_point: [2049998, 5725897],
    preview_res: 2
  },
  {
    id: 2,
    code: "dgu_tk25",
    ttoken: "map:layer.dgu_tk25",
    layer_type: 10,
    url: "http://geoportal.dgu.hr/wms?",
    layer: "TK25",
    projection: "EPSG:3857",
    visible: false,
    preview_point: [2049998, 5725897],
    preview_res: 2
  },
  {
    id: 3,
    code: "dgu_dof",
    ttoken: "map:layer.dgu_dof_2017",
    layer_type: 10,
    url: "http://geoportal.dgu.hr/wms?",
    layer: "DOF",
    projection: "EPSG:3857",
    visible: false,
    preview_point: [2049998, 5725897],
    preview_res: 2
  },
  {
    id: 4,
    code: "dof",
    ttoken: "map:layer.dof",
    layer_type: 10,
    url: null,
    layer: "VLP:VLP_DOF",
    projection: "EPSG:3765",
    visible: false,
    preview_point: [2049998, 5725897],
    preview_res: 2
  }
];

const layersData = [
  {
    id: 100,
    code: "data",
    ttoken: "map:layer.podaci",
    layer_type: 99,
    url: null,
    layer: null,
    projection: "EPSG:3765",
    visible: true,
    z_index: null,
    can_query: false,
    parent_layer_code: null
  },
  {
    id: 200,
    code: "podloga",
    ttoken: "map:layer.cartography",
    layer_type: 99,
    url: null,
    layer: null,
    projection: "EPSG:3765",
    visible: false,
    z_index: null,
    can_query: false,
    parent_layer_code: null
  },
  {
    id: 210,
    code: "3d",
    ttoken: "map:layer.3d",
    layer_type: 11,
    url: null,
    layer: "VLP:VLP_3D",
    projection: "EPSG:3765",
    visible: false,
    z_index: 14,
    can_query: false,
    parent_layer_code: "podloga"
  },
  {
    id: 110,
    code: "stabla",
    ttoken: "map:layer.stabla",
    layer_type: 11,
    url: null,
    layer: "VLP:stabla",
    projection: "EPSG:3765",
    visible: true,
    z_index: 34,
    can_query: true,
    parent_layer_code: "data"
  },
  {
    id: 120,
    code: "grmovi",
    ttoken: "map:layer.grmovi",
    layer_type: 11,
    url: null,
    layer: "VLP:grmovi",
    projection: "EPSG:3765",
    visible: true,
    z_index: 33,
    can_query: true,
    parent_layer_code: "data"
  },
  {
    id: 130,
    code: "klupe",
    ttoken: "map:layer.klupe",
    layer_type: 11,
    url: null,
    layer: "VLP:klupe",
    projection: "EPSG:3765",
    visible: true,
    z_index: 31,
    can_query: true,
    parent_layer_code: "data"
  },
  {
    id: 230,
    code: "dsm",
    ttoken: "map:layer.dsm",
    layer_type: 11,
    url: null,
    layer: "VLP:VLP_DSM",
    projection: "EPSG:3765",
    visible: false,
    z_index: 13,
    can_query: false,
    parent_layer_code: "podloga"
  },
  {
    id: 140,
    code: "kosevi",
    ttoken: "map:layer.kosevi",
    layer_type: 11,
    url: null,
    layer: "VLP:kosevi",
    projection: "EPSG:3765",
    visible: true,
    z_index: 32,
    can_query: true,
    parent_layer_code: "data"
  },
  {
    id: 240,
    code: "dem",
    ttoken: "map:layer.dem",
    layer_type: 11,
    url: null,
    layer: "VLP:VLP_DEM",
    projection: "EPSG:3765",
    visible: false,
    z_index: 11,
    can_query: false,
    parent_layer_code: "podloga"
  },
  {
    id: 150,
    code: "travnjaci",
    ttoken: "map:layer.travnjaci",
    layer_type: 11,
    url: null,
    layer: "VLP:travnjaci",
    projection: "EPSG:3765",
    visible: true,
    z_index: 21,
    can_query: true,
    parent_layer_code: "data"
  },
  {
    id: 160,
    code: "cvjetnjaci",
    ttoken: "map:layer.cvjetnjaci",
    layer_type: 11,
    url: null,
    layer: "VLP:cvjetnjaci",
    projection: "EPSG:3765",
    visible: true,
    z_index: 23,
    can_query: true,
    parent_layer_code: "data"
  },
  {
    id: 300,
    code: "sum_sast",
    ttoken: "map:layer.sumske_sastojine",
    layer_type: 11,
    url: null,
    layer: "VLP:sumske_sastojine",
    projection: "EPSG:3765",
    visible: false,
    z_index: 20,
    can_query: false,
    parent_layer_code: null
  },
  {
    id: 400,
    code: "putevi",
    ttoken: "map:layer.putevi",
    layer_type: 11,
    url: null,
    layer: "VLP:putevi",
    projection: "EPSG:3765",
    visible: false,
    z_index: 22,
    can_query: false,
    parent_layer_code: null
  },
  {
    id: 500,
    code: "photo360",
    ttoken: "map:layer.photos360",
    layer_type: 11,
    url: null,
    layer: "VLP:photos360",
    projection: "EPSG:3765",
    visible: true,
    z_index: 40,
    can_query: true,
    parent_layer_code: null
  }
];

const graphSeries = [{
  name: "Example Series A",
  data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 155, 265, 295],
}]

const pieSeries = [44, 55, 13, 43, 22]

exports.sampleData = sampleData;
exports.colors = colors;
exports.barGraphData = barGraphData;
exports.pieGraphData = pieGraphData;
exports.mapDefaults = mapDefaults;
exports.baseLayersData = baseLayersData;
exports.layersData = layersData;
exports.graphSeries = graphSeries
exports.pieSeries = pieSeries