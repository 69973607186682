import React, { ChangeEvent } from "react";

// Material-UI Components
import TextField from "@mui/material/TextField";

import { TextFilterWrapperFn } from "@/@types/ui/Table";

export const TextColumnFilter: TextFilterWrapperFn =
  (setFilterLocalStorage) =>
  ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;
      if (value) {
        setFilterLocalStorage(id, value, setFilter);
      } else {
        setFilterLocalStorage(id, undefined, setFilter);
      }
    };
    return <TextField value={filterValue} onChange={handleChange} size="small" margin="dense" />;
  };
