import * as React from "react";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI Icons
import LoginIcon from "@mui/icons-material/Login";

//MUI
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";

//Custom Components
import { appHeaderTheme } from "@/components/ThemeContext/ThemeProvider";
import AppHeaderMenuButton from "@/ui/Header/AppHeaderMenuButton";
import HeaderMenuButton from "@/ui/Header/HeaderMenuButton";
import ThemeSwitch from "@/ui/Header/ThemeSwitch";
// import LanguageSelector from "@/ui/Header/LanguageSelector";
import ProfileMenu from "@/ui/Header/ProfileMenu";
import ModuleMenu from "@/ui/Header/ModuleMenu";
import AccessibilityMenu from "@/ui/Header/AccessibilityMenu";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import HeaderIconButton from "@/ui/Header/HeaderIconButton";

//Types
import { IAppHeader } from "@/@types/ui/Header";

const AppHeader: FunctionComponent<IAppHeader> = (props) => {
  const { t } = useTranslation();
  const { modul, sidebarOpen, toggleSidebar } = props;
  const theme = useTheme();
  const hidden: boolean = useMediaQuery(theme.breakpoints.down("md"));
  const drawerWidth = 240;

  const toolbarStyle = {
    "& > button": {
      mx: { sm: 0, md: 1 }
    },
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    // borderBottom: "2px",
    borderStyle: "solid",
    borderColor: "primary.main"
  };

  const appBarStyle = {
    zIndex: theme.zIndex.drawer + 1,
    // marginLeft: sidebarOpen ? drawerWidth : 0,
    marginLeft: 0,
    // width: sidebarOpen ? `calc(100% - ${drawerWidth}px)` : "100%",
    width: "100%",
    // transition: 
    //   sidebarOpen
    //   ? theme.transitions.create(["width", "margin"], {
    //       easing: theme.transitions.easing.sharp,
    //       duration: theme.transitions.duration.enteringScreen
    //     })
    //   : theme.transitions.create(["width", "margin"], {
    //       easing: theme.transitions.easing.sharp,
    //       duration: theme.transitions.duration.leavingScreen
    //     })
  };

  return (
    <MuiThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        palette: {
          ...outerTheme.palette,
          secondary: appHeaderTheme.palette.secondary
        }
      })}
    >
      <AppBar position="fixed" sx={appBarStyle} color="secondary">
        <Toolbar variant="dense" disableGutters={true} sx={toolbarStyle}>
        {/* <Box m={0} sx={{ display: { xs: "block", md: "none" } }}>
            <AppHeaderMenuButton showCloseIcon={true} />
          </Box>
          {!sidebarOpen ? (
            <Box sx={{display: { xs: "none", md: "block"}}}>
              <HeaderMenuButton toggleSidebar={toggleSidebar} />
            </Box>
          ) : null} */}
          <ToolbarFillContent>
            <Typography variant="h6" component="h2" style={{ color: "#fff", fontSize: "1.15rem" }}>
              <NavLink
                to="/map"
                style={{
                  color: "#fff",
                  textDecoration: "none"
                }}
              >
                {hidden ? t("app_title_short") : t("app_title")}
              </NavLink>
            </Typography>
          </ToolbarFillContent>
          {/* <LanguageSelector /> */}
          <AccessibilityMenu />
          <ThemeSwitch />
          <ProfileMenu />
          {/* <ModuleMenu /> */}
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  );
};

export default AppHeader;
