import * as React from "react";
import { Fragment, FunctionComponent } from "react";

const Layers: FunctionComponent<any> = (props) => {

  return (
    <Fragment>{props.children}</Fragment>
  )
};

export default Layers;

