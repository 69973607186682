import * as React from "react";
import { useState, useContext, useEffect, FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

//MUI
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";

//Custom Components
import NavDrawer from "@/ui/NavDrawer/NavDrawer";
import menuRoutes from "@/routes/menu";
import UserContext from "@/components/UserContext/UserContext";
import Loader from "@/ui/Loader/Loader";
import AppHeader from "@/ui/Header/AppHeader";

//Types
import { IAppPage } from "@/@types/ui/AppPage";
import { UserContextType } from "@/@types/context/UserContext";
import { useTranslation } from "react-i18next";

const AppPage: FunctionComponent<IAppPage> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(getSidebarSetting());

  const { needPermission = [], menu, modul } = props;

  const navigate = useNavigate();

  const toggleSidebar = (): void => {
    localStorage.setItem("sidebar", String(!sidebarOpen));
    setSidebarOpen((prevState) => !prevState);
  };

  const forbidden: boolean = needPermission.length > 0 && !userContext.hasAnyPermission(needPermission);

  useEffect(() => {
    if (userContext && userContext.loaded && forbidden) {
      console.warn("Tried to access forbidden page, Redirecting...");
      navigate("/");
    }
  }, [userContext.loaded, forbidden]);

  return userContext && userContext.loaded && !forbidden ? (
    <div id="page">
      <AppHeader modul={modul} sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <Box m={0} sx={{display: {xs: "none", md: "block"}}}>
        <NavDrawer menu={menu || menuRoutes} open={sidebarOpen} toggleOpen={toggleSidebar} />
      </Box>
      <main id="content-wrapper" style={{ flexGrow: 1 }}>
        <Toolbar variant="dense" />
        <div id="content">{props.children}</div>
      </main>
    </div>
  ) : (
    <Loader open={true} />
  );
};

const getSidebarSetting = (): boolean => {
  let sidebarSetting = localStorage.getItem("sidebar");
  if (sidebarSetting === undefined || sidebarSetting === null) {
    return false;
  } else if (sidebarSetting === "false") {
    return false;
  } else return sidebarSetting === "true";
};

export default AppPage;
