import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Types
import { DCRecord, IDataController } from "@/@types/lib/dataController"

// Custom Components
import ModelTable from "@/ui/Table/ModelTable";

import model from "@/models/grading";
import dataController from "@/lib/dataController";

function GradingTable() {
    const { t } = useTranslation();
    const [records, setRecords] = useState<DCRecord[]>([]);

    const dc: IDataController = new dataController(model);

    // FUNCTIONS
    //---------------------------------------------------------------------------------------------------

    const refreshRecords = () => {
        dc.GetData().then((resp) => {
            if (resp.success) {
                if (Array.isArray(resp.data)) {
                    setRecords(resp.data)
                }
                else {
                    setRecords([]);
                }
            }
        });
    };


    // EFFECTS
    //---------------------------------------------------------------------------------------------------

    useEffect(() => {
        refreshRecords();
    }, []);

    //RETURN
    //---------------------------------------------------------------------------------------------------

    return (
        <ModelTable
            title={t("titles.grading")}
            records={records}
            dc={dc}
            allowSelection="one"
            allowFilter={true}
            allowExport={true}
            onRowAction={() => {}}
            allowRowAction={() => { return true;}}
        />
    )
}

export default GradingTable