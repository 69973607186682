import { FunctionComponent, useContext, useEffect } from "react";

//OpenLayers
import { DragPan } from "ol/interaction";
import { Kinetic as OlKinetic } from "ol";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlInteraction } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IDragPanInteraction } from "@/@types/components/Map/Interactions/OpenLayers";

const DragPanInteraction: FunctionComponent<IDragPanInteraction> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const defaultKinetic = new OlKinetic(-0.005, 0.05, 100);

  const options = {
    condition: undefined,
    kinetic: defaultKinetic
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, DragPan, props, options, events),
    []
  );

  return null;
};

export default DragPanInteraction;

