import React, { SyntheticEvent, useState } from "react";
import numbro from "numbro";

//Material UI
import Slider from "@mui/material/Slider";

//Types
import { NumberRangeFilterWrapperFn } from "@/@types/ui/Table";
import { Row } from "react-table";

export const NumberRangeFilter: NumberRangeFilterWrapperFn =
  (setFilterLocalStorage, fieldItems) =>
  ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    const [sliderValue, setSliderValue] = useState<number | number[] | undefined>(undefined);

    const defaultValue = [-1, -1];

    const rowsWithValueCount = preFilteredRows.filter((x: Row) => x.values[id]).length;

    preFilteredRows.forEach((row: Row) => {
      if (row.values[id]) {
        const unformatedvalue = row.values[id].props?.unformatedvalue
          ? row.values[id].props.unformatedvalue
          : row.values[id];
        if (defaultValue[0] === -1 && defaultValue[1] === -1) {
          defaultValue.splice(0, 1, unformatedvalue);
          defaultValue.splice(1, 1, unformatedvalue);
        } else {
          if (unformatedvalue < defaultValue[0]) {
            defaultValue.splice(0, 1, unformatedvalue);
          } else if (unformatedvalue > defaultValue[1]) {
            defaultValue.splice(1, 1, unformatedvalue);
          }
        }
      }
    });

    const minMaxDiff = defaultValue[1] - defaultValue[0];

    const step = minMaxDiff < 10 ? 0.1 : minMaxDiff < 100 ? 1 : minMaxDiff < 1000 ? 10 : 100;
    if (!sliderValue) {
      setSliderValue(filterValue ? filterValue : defaultValue);
    }

    const handleSliderValue = (evt: Event, newValue: number | number[]) => {
      setSliderValue(newValue);
    };

    const handleChange = (evt: Event | SyntheticEvent<Element, Event>, value: number | number[]) => {
      setFilterLocalStorage(id, value, setFilter);
    };

    return sliderValue ? (
      <Slider
        value={sliderValue}
        onChange={handleSliderValue}
        onChangeCommitted={handleChange}
        min={defaultValue[0]}
        max={defaultValue[1]}
        valueLabelDisplay="auto"
        color="primary"
        valueLabelFormat={(x) => {
          return numbro(x).format({ thousandSeparated: true, mantissa: 2 });
        }}
        // marks={true}
        step={step}
        disabled={rowsWithValueCount === 0}
      />
    ) : null;
  };
