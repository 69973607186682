import React, { useContext, useEffect, useRef } from "react";

//MUI
import { styled } from "@mui/material/styles";

//Custom components
import MapContext from "@/components/Map/MapContext";
import makeMUIControlStyle from "@/components/Map/Controls/makeMUIControlStyle";
import ButtonControl from "@/components/Map/Controls/OpenLayersExt/ButtonControl";
import LoaderContext from "@/components/LoaderContext/LoaderContext";
import { flattenLayers } from "@/lib/olHelpers";

//Services
import gsService from "@/services/gsService";

//ol
import OlLayerGroup from "ol/layer/Group";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { BoolSettings } from "@/@types/common/index";
import OlMapBrowserEvent from "ol/MapBrowserEvent";
// import { IIdentifyToolControl } from "@/@types/components/Map/Controls/Custom";
import { EventsKey } from "ol/events";
import {
  CardPosition,
  CardOverlayType,
  ICardOverlayData
} from "@/@types/views/GIS/map";

const IdentifyToolControl = (props: any) => {
    const mapContext = useContext(MapContext) as MapContextType
    const loaderContext = useContext(LoaderContext);
    const { className, tooltip, target, isIdentifying, setIsIdentifying, closeOverlays, showCardOverlay, layersCollection } = props
    const evtKeyRef = useRef<EventsKey>()
    
    useEffect(() => {
        if (!mapContext || !mapContext.map) return

        if (isIdentifying) {
            const newEvKey = mapContext.map.on("click", handleClick)
            evtKeyRef.current = newEvKey
        } else {
            if (evtKeyRef.current && evtKeyRef.current.listener) {
                mapContext.map.un("click", evtKeyRef.current.listener);
            }
        }
    },[isIdentifying])

    function handleClick(evt: OlMapBrowserEvent<any>) {

        const timeBeforeLoader = 300  // time in miliseconds before loader appears while waiting on getFeatureInfo
        let loaderNeeded = true
        let loading = false

        function handleLoader() {
            if (loaderNeeded) {
            loaderContext.toggleLoading(true)
            loading = true
            } else {
            if (loading) {
                loading = false
                loaderContext.toggleLoading(false)
            }
            }
        }
            setTimeout(() => {
                handleLoader()
            }, timeBeforeLoader)
            const item = localStorage.getItem("maplayers");
            const visibility = item ? (JSON.parse(item) as BoolSettings) : ({} as BoolSettings);
            const hiddenLayers = visibility ? Object.keys(visibility).filter((key) => visibility[key] === false) : [];
            const allLayers = layersCollection
                ? flattenLayers(layersCollection.getArray()).filter((x) => !(x instanceof OlLayerGroup))
                : [];
            const visibleLayers = allLayers.filter((x) => hiddenLayers.indexOf(x.get("id")) === -1);
            const GSLayerNames = visibleLayers.filter((x) => x.get("query") === true).map((x) => x.get("layer"));
    
            gsService.getFeatureInfo(evt.map, evt.pixel, GSLayerNames).then((resp) => {
                if (resp && Object.keys(resp).length != 0) {
                const layerKeys = Object.keys(resp);
                const features = resp[layerKeys[0]];
                const firstLayer = resp[layerKeys[0]];
                const firstFeature = firstLayer[0];
                const featureType = "generic";
                const data = {
                    position: evt.coordinate as CardPosition,
                    feature: firstFeature,
                    type: featureType as CardOverlayType,
                    record: undefined
                } as ICardOverlayData;
                //TODO: depending on ID, define data type, send different id instead of "gsinfo"
                showCardOverlay("gsinfo", data, null);
                // const showCard = () => showCardOverlay("gsinfo", data, null);
                // closeOverlays(showCard);
                } else {
                closeOverlays();
                }
            }).finally(() => {
                loaderNeeded = false
                handleLoader()
            });
    }

    return (
        <ButtonControl
            id="identify"
            className={className}
            title={tooltip}
            html={`<i class="${ isIdentifying ? 'fas fa-search-location': 'fas fa-search'}"></i>`}
            handleClick={() => setIsIdentifying((prev: boolean) => !prev)}
            target={target}
        />
    )
}

const StyledIdentifyToolControl = styled(IdentifyToolControl)(({ theme }) => {
    const styles = makeMUIControlStyle(theme);
    return {
      ...styles.control
    }
  })

export default StyledIdentifyToolControl