import { ReactElement, useEffect } from 'react';
import { useLocation } from "react-router-dom";

function ScrollToTop({ children } : { children: ReactElement }) {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
}

// import React, { useEffect } from "react";
// import { useLocation } from "react-router-dom";

// import { ScrollToTopProps } from "@/@types/components";

// const ScrollToTop: React.FunctionComponent = (props: React.PropsWithChildren<ScrollToTopProps>) => {
//   let location = useLocation();

//   const firstPart = (location: string) => {
//     if (location.length > 0) {
//       if (location[0] === "/") {
//         location = location.substring(1);
//       }

//       let parts = location.split("/");

//       if (parts.length > 0) {
//         return parts[0];
//       }
//     }
//     return "";
//   };

//   const pathNameFirstPath = firstPart(location.pathname);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathNameFirstPath]);

//   return props.children;
// };

export default ScrollToTop;
