import React from "react";

//MUI
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { Close , Check } from "@mui/icons-material";


export interface ConfirmDialogOptions {
  title?: string;
  text?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

interface ConfirmDialogProps extends ConfirmDialogOptions {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

function ConfirmDialog(props: ConfirmDialogProps) {
    const { open, title, text, cancelButtonText, confirmButtonText, onSubmit, onCancel } = props;

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' startIcon={<Close />} onClick={onCancel} >{cancelButtonText}</Button>
                <Button variant='contained' startIcon={<Check />} onClick={onSubmit} >{confirmButtonText}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;