import React, { useContext, SyntheticEvent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";

//MUI Icons
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VpnKeyIcon from '@mui/icons-material/VpnKey';

//Custom Components
import useFormController from "@/components/useFormController";
import DynamicDialog from "@/ui/Dialog/DynamicDialog";
import DialogHeader from "@/ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogActionButtonClose from "@/ui/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "@/ui/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "@/ui/Dialog/ActionButtons/DialogActionButtonSave";
import DialogToolbarMenuButton from "@/ui/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItem from "@/ui/Dialog/DialogToolbarMenuItem";
import DialogContext from "@/ui/DialogContext/DialogContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import { GridContainer, GridItem } from "@/ui/Grid";
import FormContent from "@/components/FormContent";
import { Roles } from "@/lib/roles";
import { Permissions } from "@/lib/permissions";
import { userService } from "@/services/userService";
import SetPasswordDialog from "@/views/Administration/UsersPage/UserSetPasswordDialog"

//Types
import { ClosingDetails, FormControllerProps } from "@/@types/components/formController";
import { IDialogProps } from "@/@types/ui/DialogContext";
import { ConfirmResult } from "@/@types/lib/confirmDialog";

const UserDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const { dc, mode, form, onClose } = props;

  const fUserRole = "user_role_id";
  const fPermissions = "app_permissions";

  const {
    record,
    validation,
    dataChanged,
    fields,
    subModels,
    onFieldChange,
    doInsert,
    doUpdate,
    doClose,
    doDelete,
    doRefresh
  } = useFormController(props as FormControllerProps);

  const handleFieldChange = (value: any, source: string) => {
    if (source === fUserRole) {
      switch (value.value) {
        case Roles.RegularUser.code:
          onFieldChange([Permissions.BasicAcces.code], fPermissions);
          break;
        case Roles.Administrator.code:
          onFieldChange([Permissions.BasicAcces.code, Permissions.Administration.code], fPermissions);
          break;
      }
    }
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt: SyntheticEvent) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert" });
        } else if (result.error) {
          const msg = t("error.addRecord") + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleUpdate = (evt: SyntheticEvent) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "update" });
        } else if (result.error) {
          const msg = t("error.editRecord") + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleClose = (evt: SyntheticEvent) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false, action: "cancel" });
        } else {
          if (result.shouldSave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const handleActivate = (evt: SyntheticEvent) => {
    if (record.id !== null) {
      try {
        userService.activate(Number(record.id)).then((result) => {
          if (result) {
            const msg = t("messages.activate_user_success");
            snackbarContext.showNotification(msg, "success");
            doRefresh();
            onClose({ dataChanged: true, action: "update" });
          } else {
            const msg = t("error.activate_user");
            snackbarContext.showNotification(msg, "error");
          }
        });
      } catch (error) {
        const msg = t("error.activate_user");
        snackbarContext.showNotification(msg, "error");
      }
    }
  };

  const handleDeactivate = (evt: SyntheticEvent) => {
    if (record.id !== null) {
      try {
        userService.deactivate(Number(record.id)).then((result) => {
          if (result) {
            const msg = t("messages.deactivate_user_success");
            snackbarContext.showNotification(msg, "success");
            close({ dataChanged: true, action: "update" });
          } else {
            const msg = t("error.deactiavate_user");
            snackbarContext.showNotification(msg, "error");
          }
        });
      } catch (error) {
        const msg = t("error.deactivate_user");
        snackbarContext.showNotification(msg, "error");
      }
    }
  };

  // const confirmAnonymize = (): Promise<ConfirmResult> => {
  //   const title = t("cdialogs.anonimize_warning");
  //   const text = t("cdialogs.close_text");
  //   const confirmButtonText = t("cdialogs.yes_save_changes");
  //   const cancelButtonText = t("cdialogs.no_just_close");

  //   return dialogContext.showConfirmDialog({ title, text, confirmButtonText, cancelButtonText });
  // };
  // const handleAnonymize = (evt: SyntheticEvent) => {
  //   if (record.id !== null) {
  //     try {
  //       confirmAnonymize().then((result) => {
  //         if (result.confirmed) {
  //           try {
  //             userService.anonymize(Number(record.id)).then((result) => {
  //               if (result) {
  //                 const msg = t("messages.anonymize_success");
  //                 snackbarContext.showNotification(msg, "success");
  //                 close({ dataChanged: true, action: "update" });
  //               } else {
  //                 const msg = t("error.anonymize");
  //                 snackbarContext.showNotification(msg, "error");
  //               }
  //             });
  //           } catch (error) {
  //             const msg = t("error.anonymize");
  //             snackbarContext.showNotification(msg, "error");
  //           }
  //         }
  //       });
  //     } catch (error) {
  //       const msg = t("error.anonymize");
  //       snackbarContext.showNotification(msg, "error");
  //     }
  //   }
  // };

  function handleSetPassword() {
    dialogContext.showDialog(SetPasswordDialog, {
      dc: dc,
      form: "password_set",
      mode: "insert",
      id: record.id
    }, 2, );
  }

  const close = (result: ClosingDetails) => {
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }
    dialogContext.hideDialog();
  };

  const handleDelete = (evt: SyntheticEvent) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          const msg = t("messages.user_deleted");
          snackbarContext.showNotification(msg, "success");
          close({ dataChanged: true, action: "update" });
        } else if (result.error) {
          const msg = t("error.delete") + result.error;
          snackbarContext.showNotification(msg, "error");
        }
      });
    }
  };

  let cmode = mode;
  if (record.status === 30 || record.status === 40) {
    cmode = "view";
  }
  return (
    <DynamicDialog open={true} maxWidth={"lg"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogToolbarHeading>{t("titles.user")}</DialogToolbarHeading>
          <ToolbarFillContent />
          {mode === "update" && record.status !== 40 ? (
            <DialogToolbarMenuButton>
              {record.status === 20 ? (
                <DialogToolbarMenuItem
                  onClick={handleDeactivate}
                  label={t("buttons.deactivate")}
                  icon={<CancelIcon color="action" />}
                />
              ) : null}
              {record.status === 30 ? (
                <DialogToolbarMenuItem
                  onClick={handleActivate}
                  label={t("buttons.activate")}
                  icon={<CheckCircleIcon color="action" />}
                />
              ) : null}
              {/* {record.status !== 40 ? (
                <DialogToolbarMenuItem
                  onClick={handleAnonymize}
                  label={t("buttons.anonymize")}
                  icon={<AccountCircleIcon color="action" />}
                />
              ) : null} */}
              <DialogToolbarMenuItem
                onClick={handleDelete}
                label={t("buttons.delete")}
                icon={<DeleteForeverIcon color="action" />}
              />
               <DialogToolbarMenuItem
                  onClick={handleSetPassword}
                  label={t("buttons.set_password")}
                  icon={<VpnKeyIcon color="action" />}
                />
            </DialogToolbarMenuButton>
          ) : null}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <GridContainer>
                <FormContent
                  fieldNames={["username", "password", "password_confirm", "email", "first_name", "last_name"]}
                  record={record}
                  validation={validation}
                  fields={fields}
                  onFieldChange={handleFieldChange}
                  mode={cmode}
                  columns={1}
                  subModels={subModels}
                />
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <GridContainer>
                <FormContent
                  fieldNames={["user_role_id", "app_permissions", "remarks"]}
                  record={record}
                  validation={validation}
                  fields={fields}
                  onFieldChange={handleFieldChange}
                  mode={cmode}
                  columns={1}
                  subModels={subModels}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {mode === "insert" ? (
          <DialogActionButtonAdd variant="contained" onClick={handleInsert} />
        ) : mode === "update" ? (
          <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
        ) : null}
      </DialogActions>
    </DynamicDialog>
  );
};

export default UserDialog;
