import React, { Fragment, useEffect, useState } from "react";
import { MouseEvent, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

// MUI
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";

// MUI Icons
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ListIcon from "@mui/icons-material/List";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HistoryIcon from "@mui/icons-material/History";
import UpdateIcon from "@mui/icons-material/Update";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

// Custom Components
import { dateOptions, dateSubOptions } from "@/lib/dateFilterOptions";

//Types
import { IDate } from "@/@types/common";
import { DateFilterWrapperFn, IDateColumnFilterProps } from "@/@types/ui/Table";
import { DateHeadingValue } from "@/@types/lib/dateFilterOptions";

export const dateColumnFilter: DateFilterWrapperFn =
  (setFilterLocalStorage) =>
  ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    return (
      <DateColumnFilter
        // id={id}
        filterValue={filterValue}
        // setFilter={setFilter}
        onFilterChange={(value: IDate | null) => setFilterLocalStorage(id, value, setFilter)}
      />
    );
  };

const getInitialValue = (filterValue: string) => {
  if (filterValue === "all") return filterValue;
  let res = "";
  Object.keys(dateSubOptions).forEach((k) => {
    if (res !== "") return;
    dateSubOptions[k as DateHeadingValue].forEach((subOpt) => {
      if (res !== "") return;
      if (filterValue === subOpt.value) {
        res = k;
      }
    });
  });
  return res === "" ? "all" : res;
};

function DateColumnFilter(props: IDateColumnFilterProps) {
  const { t } = useTranslation();
  const { filterValue, onFilterChange } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuHovered, setMenuHovered] = useState("");
  const [menuValue, setMenuValue] = useState("all");
  const [open, setOpen] = useState(false);

  const handleOpen = (evt: SyntheticEvent) => {
    setOpen(true);
  }

  const handleClose = (evt: SyntheticEvent) => {
    setOpen(false);
  }

  const handleHover = (evt: MouseEvent<HTMLLIElement>, menu: string) => {
    if (menu == "all") {
      setAnchorEl(null);
    } else if (open) {
      setAnchorEl(evt.currentTarget);
      setMenuHovered(menu);
    }
  };

  const handleChange = (evt: SelectChangeEvent) => {
    const value = evt.target.value;
    // console.log('change event', value);
    // if (!evt) return;
    // const { target } = evt;
    // if (!target) return;
    // const { value } = (target);
    if (!value) return;

    switch (value) {
      case "all":
        onFilterChange("");
        setOpen(false);
        // console.log('setting menu value', value)
        setMenuValue(value);
        break;
      // case "custom":
      //   setPopoverOpen(true);
      //   setSelectOpen(false);
      //   break;
      default:
        evt.preventDefault();
        break;
    }
  };

  // Function handles clicks on popper Menuitems
  const handleMenuItemClick = (evt: SyntheticEvent, value: string, menuHovered: string) => {
    // console.log(value, menuHovered);
    onFilterChange(value);
    setMenuValue(menuHovered);
    setOpen(false);
  };

  // Returns filterValue to the Select component through renderValue
  const getFilterValue = (value: string) => {
    if (filterValue) {
      return t("datefilter." + filterValue);
    } else {
      return t("datefilter.all");
    }
  };

  useEffect(() => {
    setMenuValue(getInitialValue(filterValue));
  }, [])

  // Sets the popper anchor to null if select is not open
  useEffect(() => {
    if (!open) {
      setAnchorEl(null);
    }
  }, [open]);

  // console.log('render select', menuValue);

  return (
    <Fragment>
      <Select
        open={open}
        renderValue={getFilterValue}
        value={menuValue}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        input={<OutlinedInput size="small" margin="dense" />}
      >
        <MenuItem
          onMouseEnter={(evt) => handleHover(evt, "all")}
          onClick={(evt) => handleMenuItemClick(evt, "all", "all")}
          value="all"
        >
          <ListItemIcon>
            <FilterListOffIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("common.all")}</ListItemText>
        </MenuItem>
        {dateOptions.map(({ title, value }, i: number) => (
          <MenuItem key={i} value={value as string} onMouseEnter={(evt) => handleHover(evt, value as string)}>
            <ListItemIcon>
              <ListIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t(title)}</ListItemText>
            <ExpandMoreIcon
              fontSize="small"
              sx={{
                transform: anchorEl !== null && value === menuHovered ? "rotate(90deg)" : "rotate(-90deg)"
              }}
            />
          </MenuItem>
        ))}
      </Select>
      <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} placement="right" style={{ zIndex: 1400 }}>
        <Paper elevation={3} style={{ borderBottomLeftRadius: "0px", borderTopLeftRadius: "0px" }}>
          <MenuList>
            {(dateSubOptions[menuHovered as DateHeadingValue] || []).map(({ value, label, icon }, i) => (
              <MenuItem key={i} value={value} onClick={(evt) => handleMenuItemClick(evt, value, menuHovered)}>
                { icon ?
                <ListItemIcon>
                  { icon === "history" ? <HistoryIcon fontSize="small" />
                  : icon === "future" ? <UpdateIcon fontSize="small" />
                  : <AccessTimeIcon fontSize="small" />}
                </ListItemIcon>
                : null
                }
                {/* <ListItemIcon>
                  <FilterListIcon fontSize="small" />
                </ListItemIcon> */}
                <ListItemText>{t(label)}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Popper>
    </Fragment>
  );
}
