import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

//MUI Components
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

//Types
import { MultiFilterWrapperFn, PickerValue } from "@/@types/ui/Table";
import { PickerItem } from "@/@types/controls/controls";
import { Row } from "react-table";

export const MultiColumnFilter: MultiFilterWrapperFn =
(setFilterLocalStorage, subModelsWithData, fieldItems) => ({column: {filterValue, setFilter, preFilteredRows, id}}) => {
  const { t } = useTranslation();

  const options = React.useMemo(() => {
    const opts = new Set();
    if (fieldItems) {
      fieldItems.labels.map((label,l) => {
        const found_value = preFilteredRows.find((pfr: Row) => {
          const pfrValue = pfr.values[id];
          const valIter = fieldItems.values[l]
          if (Array.isArray(pfrValue)) {
            return pfrValue.indexOf(valIter) >= 0;
          } else {
            return pfrValue === valIter
          }
        });
          if (found_value !== undefined) {
            opts.add({
              value: fieldItems.values[l],
              label: label
            });
          }
        });
        return Array.from(opts.values());
      }
    else if (subModelsWithData) {
      if(subModelsWithData[id]) {
        const { records } = subModelsWithData[id];
        const opts = new Set(records);
        return Array.from(opts.values()) as Array<PickerItem>;
      }
      return [];
    }
    return [];
  }, [id, subModelsWithData]);

  const handleChange = (evt: SelectChangeEvent) => {
    const value = evt.target.value;
    const _selected = Array.isArray(value) ?
          value.map((opt) => parseInt(opt as string))
          : [value];
        setFilterLocalStorage(id, _selected || undefined, setFilter);

  }

  return (
    <Select
      //@ts-ignore
      value={Array.isArray(filterValue) ? filterValue.map(x => x.label) : []}
      onChange={handleChange}
      multiple
      variant="outlined"
      input={<OutlinedInput margin="dense" size="small" />}
    >
      <MenuItem value="" >
          {t("common.all")}
        </MenuItem>
      {
        (options as Array<PickerValue>).map((option, i) => (
          <MenuItem key={i} value={/*@Piero this looks hacky*/String(option.value)}>
            {t(option.label)}
          </MenuItem>
        ))
      }
    </Select>
  );
}
