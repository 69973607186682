import React from "react";
import Chart from "react-apexcharts";

function BarChart (props: any) {
    const { series, color } = props

    const options = {
      chart: {
        type: 'bar',
      },
      colors: [color],
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
          columnWidth: '80%'
        }
      },
      title: {
        text: 'Graph example',
        align: 'center'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      }
    }

  return (
    <Chart
      type='bar'
      // @ts-ignore
      options={options}
      series={series}
      height="300px"
    />
  );
}


export default BarChart