import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";

//Material-UI Icons
import LockIcon from "@mui/icons-material/Lock";

//Types
import { IBoolControlProps } from "@/@types/controls/controls";
import { PickerItemValue } from "@/@types/models/model";

function BoolControl(props: IBoolControlProps) {
  const { field, onChange, value, helperText, validation, formMode, controlMode } = props;

  const { t } = useTranslation();

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const checked = evt.target.checked;
    const ind = checked ? 1 : 0;
    const value = field.items.values[ind];

    onChange(value, field.source);
  };

  const getSelectedIndex = () => {
    let ind = field.items.values.indexOf(value as PickerItemValue);
    if (ind === -1) {
      if (field.items.default !== undefined) {
        ind = field.items.values.indexOf(field.items.default);
        if (ind === -1) {
          ind = 0;
        }
      } else {
        ind = 0;
      }
    }
    return ind;
  };

  const hasError = validation && validation.valid === false;
  const isReadOnly = controlMode === "view" || field === undefined || field.readonly;

  const selectedIndex = getSelectedIndex();
  const valueLabel = t(field.items.labels[selectedIndex]);
  const checked = selectedIndex === 1 ? true : false;

  const label = field.ttoken ? t(field.ttoken) : field.title;
  const myHelperText = field && field.tooltip ? t(field.tooltip) : helperText ? helperText : " ";

  return (
    <TextField
      margin="none"
      fullWidth
      error={hasError}
      label={formMode === "form" ? label : null}
      value={valueLabel}
      color="primary"
      InputProps={{
        readOnly: true,
        endAdornment: (
          <Fragment>
            {isReadOnly ? (
              <LockIcon style={{ fontSize: 20 }} color="disabled" />
            ) : (
              <Switch
                checked={checked}
                onChange={handleChange}
                disabled={isReadOnly ? true : undefined}
                color="primary"
              />
            )}
          </Fragment>
        )
      }}
      variant={"outlined"}
      helperText={hasError ? t(validation.msg) : myHelperText}
    />
  );
}

export default BoolControl;
