import api from "@/lib/api";
import { IApiResponse, Record } from "@/@types/lib/api/api";
import { DCRecord } from "@/@types/lib/dataController";

//Types
import { ActivateData } from "@/@types/services/authService";
import { AnonymizeData, DeactivateData, IUserService, SetSettingsData, SetPassword } from "@/@types/services/userService";
import { IUserSettings } from "@/@types/common";

export const userService: IUserService = {
  activate,
  deactivate,
  anonymize,
  setUserSettings,
  setPassword
};

const userApiUrl = "users/";

function activate(id: number): Promise<ActivateData> {
  const apiInstance = new api();
  const url = userApiUrl + id.toString() + "/activate";

  return apiInstance.Call(url, "patch");
}

function deactivate(id: number): Promise<DeactivateData> {
  const apiInstance = new api();
  const url = userApiUrl + id.toString() + "/deactivate";

  return apiInstance.Call(url, "patch");
}

function anonymize(id: number): Promise<AnonymizeData> {
  const apiInstance = new api();
  const url = userApiUrl + id.toString() + "/anonymize";

  return apiInstance.Call(url, "patch");
}

function setUserSettings(id: number, settings: IUserSettings): Promise<SetSettingsData> {
  const apiInstance = new api();
  const url = userApiUrl + id.toString() + "/set-settings";

  return apiInstance.Call(url, "put");
}

function setPassword(id: number ,data: DCRecord): Promise<SetPassword> {
  const apiInstance = new api();
  const url = userApiUrl + id.toString() + "/set-password";

  return apiInstance
    .Call(url, "patch", {
      password: data.password,
    })
    .then((resp) => {
      if (resp.success) {
        return Promise.resolve(resp)
      } else {
        return Promise.reject(resp.error)
      }
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}