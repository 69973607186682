import React, { useContext, useState, SyntheticEvent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from "@mui/material/StepContent";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";


//MUI Icons
import CancelIcon from "@mui/icons-material/Cancel";
import RepeatIcon from "@mui/icons-material/Repeat";

//Custom Components
import useFormController from "@/components/useFormController";
import DynamicDialog from "@/ui/Dialog/DynamicDialog";
import DialogHeader from "@/ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogActionButton from "@/ui/Dialog/DialogActionButton";
import DialogActionButtonClose from "@/ui/Dialog/ActionButtons/DialogActionButtonClose";
import DialogContext from "@/ui/DialogContext/DialogContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import FormContent from "@/components/FormContent";
import RadioControl from "@/controls/RadioControl";
import TextControl from "@/controls/TextControl";

//Types
import { ClosingDetails, FormControllerProps } from "@/@types/components/formController";
import { DialogContextType, IDialogProps } from "@/@types/ui/DialogContext";
import { ConfirmResult } from "@/@types/lib/confirmDialog";
import { IFieldPickerItems, IFieldText, IPickerItems } from "@/@types/models/model";
import { PickerItem } from "@/@types/controls/controls";
import { DoNotDisturbOnTotalSilenceTwoTone } from "@mui/icons-material";


const GradingDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext) as DialogContextType;
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { label: "grading.steps.ranjiva_skupina", description: "grading.steps.desc_ranjiva_skupina" },
    { label: "grading.steps.element_pristupacnosti", description: "grading.steps.desc_element_pristupacnosti" },
    { label: "grading.steps.ocjena", description: "grading.steps.desc_ocjena" },
    { label: "grading.steps.komentar", description: "grading.steps.desc_komentar" }
  ]

  const { dc, mode, form, onClose } = props;

  const {
    record,
    validation,
    dataChanged,
    fields,
    subModels,
    onFieldChange,
    doInsert,
    doUpdate,
    doClose,
    doDelete,
    doRefresh,
    doClear
  } = useFormController(props as FormControllerProps);


  // const handleUpdate = (evt: SyntheticEvent) => {
  //   if (doUpdate) {
  //     doUpdate().then((result) => {
  //       if (result.success) {
  //         close({ dataChanged: true, action: "update" });
  //       } else if (result.error) {
  //         const msg = t("error.editRecord") + result.error;
  //         snackbarContext.showNotification(msg, "error");
  //       } else if (result.validationPass === false) {
  //         //do nothing
  //       }
  //     });
  //   }
  // };

  const handleSend = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    doInsert().then(resp => {
      if (resp.success) {
        console.log(resp);

      }
    })

  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleReset = () => {
    doClear();
    setActiveStep(0);
  }

  const handleClose = (evt: SyntheticEvent) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false, action: "cancel" });
        } else {
          if (result.shouldSave) {
            // handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result: ClosingDetails) => {
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }
    dialogContext.hideDialog();
  };

  const generateItems = (rs: number): IPickerItems => {
    switch (rs) {
      case 1:
        return {
          labels: [1, 2, 3, 4, 5, 6].map(x => `grading.rs${((record.category_id) as PickerItem).value}.ep${x}`),
          values: [1, 2, 3, 4, 5, 6].map(x => x + 10 * ((((record.category_id) as PickerItem).value) as number))
        }
      case 2:
      case 4:
      case 5:
        return {
          labels: [1, 2, 3, 4].map(x => `grading.rs${((record.category_id) as PickerItem).value}.ep${x}`),
          values: [1, 2, 3, 4].map(x => x + 10 * ((((record.category_id) as PickerItem).value) as number))
        }
      case 3:
        return {
          labels: [1, 2, 3].map(x => `grading.rs${((record.category_id) as PickerItem).value}.ep${x}`),
          values: [1, 2, 3].map(x => x + 10 * ((((record.category_id) as PickerItem).value) as number))
        }
      default: {
        return {
          labels: [],
          values: []
        }
      }
    }
  }

  const fieldCategory = fields.find(x => x.source === "category_id") as IFieldPickerItems;
  if (fieldCategory) {
    fieldCategory.title = fieldCategory.ttoken = "";
  }
  const fieldComment = fields.find(x => x.source === "komentar") as IFieldText;

  const fieldReason = fields.find(x => x.source === "reason_id") as IFieldPickerItems;
  if (fieldReason) {
    fieldReason.title = fieldReason.ttoken = "";
    if (record.category_id) {
      fieldReason.items = generateItems((record.category_id as PickerItem).value as number);
    }
  }

  return (
    <DynamicDialog open={true} maxWidth={"sm"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogToolbarHeading>{t("titles.grading")}</DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel optional={
                  (index === 0 && activeStep > 0 && record.category_id) ?
                    <Typography variant="caption">{(record.category_id as PickerItem).label}</Typography>
                    : (index === 1 && activeStep > 1 && record.reason_id) ?
                      <Typography variant="caption">{(record.reason_id as PickerItem).label}</Typography>
                      : (index === 2 && activeStep > 2 && record.ocjena) ?
                        <Rating name="size-small" value={record.ocjena as number} readOnly={true} size="small" />
                        : null
                }>{t(step.label)}</StepLabel>
                <StepContent>
                  <Typography paragraph={true}>{t(step.description)}</Typography>
                  {
                    activeStep === 0 && fieldCategory ?
                      <>
                        <RadioControl
                          field={fieldCategory}
                          formMode="form"
                          controlMode="edit"
                          value={record.category_id as number | null}
                          validation={validation["category_id"]}
                          onChange={(newValue, source) => {
                            onFieldChange(newValue, "category_id");
                          }}
                        />
                      </>
                      :
                      activeStep === 1 && fieldReason ?
                        <>
                          <RadioControl
                            field={fieldReason}
                            formMode="form"
                            controlMode="edit"
                            value={record.reason_id as number | null}
                            validation={validation["reason_id"]}
                            onChange={(newValue, source) => {
                              onFieldChange(newValue, "reason_id");
                            }}
                          />
                        </>
                        :
                        activeStep === 2 ?
                          <>
                            <Rating
                              name="simple-controlled"
                              value={record.ocjena as number | undefined}
                              size="large"
                              onChange={(event, newValue) => {
                                onFieldChange(newValue, "ocjena");
                              }}
                            />
                          </>

                          :
                          activeStep === 3 && fieldComment ?
                            <>
                              <TextControl
                                field={fieldComment}
                                formMode="form"
                                controlMode="edit"
                                value={record.komentar as string}
                                validation={validation["layerUserGivenName"]}
                                onChange={(value, source) => onFieldChange(value, source)}
                              />
                            </>
                            : null
                  }
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={index === steps.length - 1 ? handleSend : handleNext}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={
                          (activeStep === 0 && record.category_id === undefined)
                          || (activeStep === 1 && record.reason_id === undefined)
                          || (activeStep === 2 && record.ocjena === undefined)
                        }
                      >
                        {index === steps.length - 1 ? t("buttons.send") : t("buttons.next")}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("buttons.back")}
                      </Button>
                    </div>
                  </Box>

                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>Hvala na vašoj ocjeni</Typography>
              <Button startIcon={<RepeatIcon />} onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                {t("buttons.repeat")}
              </Button>
            </Paper>
          )}

        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose onClick={handleClose} variant="outlined" />
      </DialogActions>

    </DynamicDialog>
  );
};

export default GradingDialog;
