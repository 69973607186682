import React, { useContext } from "react";
import clsx from "clsx";

//MUI
import { Typography, IconButton } from "@mui/material";

//MUI Icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

//MUI LAB
import { TreeItemContentProps, useTreeItem } from "@mui/lab/TreeItem";

//Components
import MapContext from "@/components/Map/MapContext";
import TimeSeriesSlider from '@/ui/Slider/TimeSeriesSlider'
import OpacitySlider from '@/ui/Slider/OpacitySlider'
import { findLayer } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";

const LayerTreeItemCustomContent = React.forwardRef(function LayerTreeItemCustomContent(
  props: TreeItemContentProps,
  ref
) {
  const mapContext = useContext(MapContext) as MapContextType;
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon, is, id, placeholder } = props;
  const checked = props["aria-checked"];
  // @ts-ignore
  const { layerExtent, zoomable, dataVisible } = props

  const timeseries = placeholder ? placeholder.split(";") : undefined;
  const ticks = timeseries ? timeseries.length - 1 : 0;
  const hasTimeSeriesSlider = timeseries && timeseries.length > 0;

  const opacitySliderItems = ['app-dof-image', 'app-dof-tilewms', 'app-dof-wmts', 'app-dof-tms']
  const hasOpacitySlider = id && opacitySliderItems.includes(id)

  const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
    useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleSelection(event);
  };

  const handleChangeCommited = (event: React.SyntheticEvent | Event, value: number | Array<number>) => {
    const time = handleValueLabel(value as number);
    if (id && time) {
      mapContext.setLayerTime(id, time);
    }
  };

  const handleZoomToLayer = () => {
    if (mapContext && mapContext.map && layerExtent) {
      const view = mapContext.map.getView();
      view.fit(layerExtent, { padding: [20, 20, 20, 220], duration: 1000 });
    }
  }

  const handleChangeOpacity = (event: Event, value: number | Array<number>, activeThumb: number) => {
    if (mapContext && mapContext.map && typeof value === 'number') {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setOpacity(value / 100);
    }
  }

  const handleValueLabel = (x: number) => {
    const timeseries = placeholder ? placeholder.split(";") : undefined;

    if (timeseries && timeseries.length > x) {
      return timeseries[x];
    } else {
      return '?'
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      {/* {dataVisible ?
        <IconButton onClick={handleZoomToLayer} color="primary" disabled={!layerExtent} edge='start' sx={!zoomable ? { visibility: 'hidden' } : null}>
          <LocationOnIcon />
        </IconButton>
        : <IconButton disabled={true} edge='start'>
          <VisibilityOffIcon color="disabled" />
        </IconButton>
      } */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
        {label}
      </Typography>
      {hasTimeSeriesSlider ? <TimeSeriesSlider
        ticks={ticks}
        checked={checked}
        handleValueLabel={handleValueLabel}
        handleChangeCommited={handleChangeCommited}
      /> : null}
      {hasOpacitySlider ? <OpacitySlider
        checked={checked}
        handleChange={handleChangeOpacity}
      /> : null}
    </div>
  );
});

export default LayerTreeItemCustomContent;
