import React, { useContext} from "react";

//OL
import OlLayerGroup from "ol/layer/Group";
import OlBaseLayer from "ol/layer/Base";
import OlCollection from "ol/Collection";

//Custom Components
import TileLayer from "@/components/Map/Layers/TileLayer";
import MapContext from "@/components/Map/MapContext";
import { flattenLayers } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";

const gs_url = process.env.REACT_APP_GEOSERVERPATH;

type GeoLayerCombinedProps = {
  id: string;
  layersCollection: OlCollection<OlBaseLayer>
}

function GeoLayerCombined(props: GeoLayerCombinedProps) {
  const mapContext = useContext<MapContextType | null>(MapContext);
  const { id, layersCollection, ...otherProps } = props;

  const visibility = mapContext?.getLayerVisibility()
  const hiddenLayers = visibility ? Object.keys(visibility).filter(key => visibility[key] === false) : [];
  // console.log(hiddenLayers);

  const allLayers = flattenLayers(layersCollection.getArray()).filter(x => !(x instanceof OlLayerGroup));
  // console.log(allLayers);

  const visibleLayers = allLayers.filter(x => hiddenLayers.indexOf(x.get("id")) === -1);
  // console.log(visibleLayers);

  const GSlayerNames = visibleLayers.map(x => x.get("layer"));
  // console.log(GSlayerNames);

  const visible = GSlayerNames.length > 0;

  const format = visibleLayers.length > 0 ? visibleLayers[0].get('format') : "image/png8";

  const tiled = visibleLayers.length > 0 ? visibleLayers[0].get('tiled') : true;

  // console.log('render GSCombined', id, GSlayerNames, visible);

  return (
    <TileLayer
      id={id}
      title={id}
      {...otherProps}
      visible={visible}
      wms={{
        url: gs_url,
        params: {
          LAYERS: GSlayerNames.join(","),
          FORMAT: format,
          TILED: tiled
        }
      }}
    />
  );
}

export default GeoLayerCombined;
