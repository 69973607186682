import React, { useEffect } from "react";
import { FunctionComponent, useState } from "react";
import moment from "moment";

import UserContext from "@/components/UserContext/UserContext";
import { userService } from "@/services/userService";

//Types
import { Permission, IUserState, IUserStatePreParsed, IUserSettings } from "@/@types/common";

type UserProviderProps = {
  children: JSX.Element | JSX.Element[]
}

const UserProvider: FunctionComponent<UserProviderProps> = (props:React.PropsWithChildren<UserProviderProps>) => {

  const [loaded, setLoaded] = useState<boolean>(false);
  const [user, setUser] = useState<IUserState>({
    userId: 0,
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    fullName: "",
    permissionIds: [],
    roleId: 0,
    userSettings: {},
    expireTime: null,
    userType: null,
  });

  const setUserData = (data: IUserStatePreParsed): void => {
    const { user_id, username, first_name, last_name, email, permission_ids, role_id, usersettings, session_expires_in_sec, user_type } = data;

    const expireTime = session_expires_in_sec ? moment().add(session_expires_in_sec, 'seconds') : moment()

    setLoaded(true);
    setUser({
      userId: user_id,
      username: username,
      firstName: first_name,
      lastName: last_name,
      email: email,
      fullName: first_name + " " + last_name,
      permissionIds: permission_ids,
      roleId: role_id,
      userSettings: usersettings,
      expireTime: expireTime,
      userType: user_type
    });
  };

  const setUserSettings = (settings: IUserSettings, otherSettings = false): void => {
    window.localStorage.userSettings = JSON.stringify(settings);
    // setUser((prevState: IUserState) => {
    //   let currentSettings = prevState && prevState.userSettings ? prevState.userSettings : {};
    //   Object.keys(settings).forEach((key) => {
    //     if (otherSettings) {
    //       if (!currentSettings.hasOwnProperty("other_settings") || currentSettings.other_settings === null) {
    //         currentSettings.other_settings = {};
    //       }
    //       currentSettings.other_settings![key] = settings[key];
    //     } else {
    //       //@ts-ignore
    //       currentSettings[key] = settings[key];
    //     }
    //   });
    //   return {
    //     ...user, userSettings: currentSettings
    //   }
    // });
  };

  const setLayerVisible = (id: string, isVisible: boolean) => {
    console.log(id, isVisible);
    //TODO: implement
    // const userSettings = user.userSettings || {};
    // const map_layer_visibility = userSettings.map_layer_visibility || {};

    // const new_map_layer_visibility = Object.assign({}, map_layer_visibility);
    // const oldValue = new_map_layer_visibility[id];
    // // if (oldValue !== isVisible) {
    //   new_map_layer_visibility[id] = isVisible;

    //   setUserSetting({
    //     map_layer_visibility: new_map_layer_visibility
    //   });
    // // }
  }

  const setMultipleLayerVisible = (obj: object) => {
    console.log(obj);
    //TODO: implement
    // const userSettings = this.state.userSettings || {};
    // const map_layer_visibility = userSettings.map_layer_visibility || {};

    // const new_map_layer_visibility = Object.assign({}, map_layer_visibility);
    // Object.keys(obj).forEach((key) => {
    //   new_map_layer_visibility[key] = obj[key];
    // });

    // this.setUserSetting({
    //   map_layer_visibility: new_map_layer_visibility
    // });
  }

  const setRecentTab = (page: string, tab: number | string): void => {
    const key: string = "tabs";
    // .getItem returns null if item is not find in localStorage
    // JSON.parse will return null if null is passed to it
    const item = localStorage.getItem(key)
    const recentTabs = item ? JSON.parse(item) : null;
    if (recentTabs === null || Object.keys(recentTabs).length === 0) {
      localStorage.setItem(key, JSON.stringify({ [page]: tab }));
    } else {
      recentTabs[page] = tab;
      localStorage.setItem(key, JSON.stringify(recentTabs));
    }
  };

  const getRecentTab = (page: string, defaultTab = 0): string | number => {
    const key: string = "tabs";
    // .getItem returns null if item is not find in localStorage
    // JSON.parse will return null if null is passed to it
    const item = localStorage.getItem(key)
    const recentTabs = item ? JSON.parse(item) : null;
    if (recentTabs === null || Object.keys(recentTabs).length === 0 || recentTabs[page] === null || recentTabs[page] === undefined) {
      setRecentTab(page, defaultTab);
      return defaultTab;
    } else {
      return recentTabs[page];
    }
  };

  const setRecent = (key: string, identifier: string, details: object): void => {
    const item = localStorage.getItem(key)
    const recent = item ? JSON.parse(item) : null;
    if (recent === null) {
      localStorage.setItem(key, JSON.stringify({ [identifier]: details }));
    } else {
      recent[identifier] = details;
      localStorage.setItem(key, JSON.stringify(recent));
    }
  };

  const getRecent = (key: string, identifier: string, defaultDetail = {}): object => {
    const item = localStorage.getItem(key)
    const recent = item ? JSON.parse(item) : null;
    if (recent === null || recent[identifier] === null || recent[identifier] === undefined) {
      setRecent(key, identifier, defaultDetail);
      return defaultDetail;
    } else {
      return recent[identifier];
    }
  };

  function setRecentFilters<ValueType> (identifier: string, id: string, filter: ValueType, clearFilter = false): void {
    const item = localStorage.getItem("filters")
    const recent = item ? JSON.parse(item) : null;
    if (clearFilter) {
      if (recent !== null && recent !== undefined) {
        recent[identifier] = undefined;
        localStorage.setItem("filters", JSON.stringify(recent));
      }
    } else {
      if (recent === null) {
        localStorage.setItem("filters", JSON.stringify({ [identifier]: { [id]: filter } }));
      } else {
        if (recent[identifier] === undefined) {
          recent[identifier] = { [id]: filter };
        } else {
          recent[identifier][id] = filter;
        }
        localStorage.setItem("filters", JSON.stringify(recent));
      }
    }
  };

  const hasAnyPermission = (needAnyPermission: Permission[]): boolean => {
    if (needAnyPermission === null || needAnyPermission === undefined) {
      return true;
    } else if (!Array.isArray(needAnyPermission)) {
      needAnyPermission = [needAnyPermission];
    } else if (needAnyPermission.length === 0) {
      return true;
    }
    const permissionCodes = {};
    needAnyPermission.forEach(x => {
      //@ts-ignore
      permissionCodes[x.code] = true;
    });

    //@ts-ignore
    return user && user.permissionIds ? user.permissionIds.find(x => permissionCodes[x] !== undefined) !== undefined : false;
  };

  // Maybe deprecated (definitely buggy)
  // hasAnyRole(needAnyRole) {
  //   if (needAnyRole === null || needAnyRole === undefined) {
  //     return true;
  //   } else if (!Array.isArray(needAnyRole)) {
  //     needAnyRole = [needAnyRole];
  //   } else if (needAnyRole.length === 0) {
  //     return true;
  //   }

  //   const roleCodes = needAnyRole.map(x => x.code);
  //   return this.roleIds.find(x => roleCodes.indexOf(x) >= 0) !== undefined;
  // }

  useEffect(() => {
    if(user && user.userSettings && user.userId !== 0){
      userService.setUserSettings(user.userId, user.userSettings);
    }
  }, [user && user.userSettings])

  const providerValue = {
    loaded,
    id: user.userId,
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    fullName: user.fullName,
    roleId: user.roleId,
    permissionIds: user.permissionIds,
    userSettings: user.userSettings,
    expireTime: user.expireTime,
    userType: user.userType,
    setUserData,
    setUserSettings,
    hasAnyPermission,
    getRecent,
    setRecent,
    setRecentFilters,
    getRecentTab,
    setRecentTab,
    setLayerVisible,
    setMultipleLayerVisible
  };

  return (
    <UserContext.Provider value={providerValue}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;

