import { IModel } from "@/@types/models/model";

const User: IModel = {
  title: "Users",
  apiPath: "data/poi",
  forms : {
    "default": {
      // fields: [ "id", "objectid", "opis"]
      fields: [ "id", "naziv", "vrsta"]
    },
    "infoCard": {
      fields: [ "id", "naziv", "vrsta"]
    },
  },
  listViews: {
    "default": {
      fields: ["id", "naziv", "vrsta"]
    }
  },
  fields: [
    {
      title: "id",
      source: "id",
      ttoken: "common.id",
      type: "numeric",
      idattr: true,
      readonly: true
    },
    {
      title: "st_astext",
      source: "st_astext",
      ttoken: "common.objectid",
      type: "text",
      readonly: true
    },
    // {
    //   title: "opis",
    //   source: "opis",
    //   ttoken: "common.opis",
    //   type: "text",
    //   readonly: true
    // },
    // {
    //   title: "fid",
    //   source: "fid",
    //   ttoken: "common.opis",
    //   type: "numeric",
    //   readonly: true
    // },
    {
      title: "naziv",
      source: "naziv",
      ttoken: "poi.name",
      type: "text",
      readonly: true
    },
    {
      title: "vrsta",
      source: "vrsta",
      ttoken: "poi.category",
      type: "text",
      readonly: true
    },
  ]
}

export default User;
