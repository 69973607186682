import React, { Fragment, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import clsx from "clsx";

//Material-UI Core Components
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

//Material-UI Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CommentIcon from '@mui/icons-material/Comment';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

//Custom Components
import MapCard from "@/ui/MapCard/MapCard";
import MapCardHeader from "@/ui/MapCard/MapCardHeader";
import MapCardAvatar from "@/ui/MapCard/MapCardAvatar";
import MapCardContent from "@/ui/MapCard/MapCardContent";
import MapCardActions from "@/ui/MapCard/MapCardActions";
import MapCardActionButton from "@/ui/MapCard/MapCardActionButton";
import { formats } from "@/lib/formats";
import dataController from "@/lib/dataController";
import MapCardHeaderButtonClose from "@/ui/MapCard/MapCardHeaderButtonClose";
import DialogContext from "@/ui/DialogContext/DialogContext";
import { GridContainer, GridItem } from "@/ui/Grid";
import ModelTable from "@/ui/Table/ModelTable";
// import { dataService } from "@/services/dataService";
import FormContent from "@/components/FormContent";
import UserContext from "@/components/UserContext/UserContext";

import GradingDialog from "@/views/GIS/MapPage/GradingDialog";

// import ImageSlider from "@/components/ImageSlider";

// import modelCvjetnjaci from "Models/cvjetnjaci";
// import modelGrmovi from "Models/grmovi";
// import modelKlupe from "Models/klupe";
// import modelKosevi from "Models/kosevi";
// import modelPutevi from "Models/putevi";
// import modelStabla from "Models/stabla";
// import modelSumskeSastojine from "Models/sumske-sastojine";
// import modelTravnjaci from "Models/travnjaci";
// import modelPhotos360 from "Models/photos360";
import modelComments from "@/models/comments";
import modelData from "@/models/data";
import modelGrading from "@/models/grading";

// import DataDialog from "Views/MapPage/DataDialog";
// import InspectionDialog from "Views/MapPage/InspectionDialog";
// import CommentDialog from "@/views/GIS/MapPage/CommentDialog"

//Types
import { GSInfoCardProps } from "@/@types/views/GIS/map";

function GSInfoCard(props: GSInfoCardProps) {
  const { t } = useTranslation();

  const initialRecod = props.featureData.record ? props.featureData.record : props.featureData.feature.properties;

  const [record, setRecord] = useState(initialRecod);
  // const [refreshCounter, setRefreshCounter] = useState(0);
  // const [loadStatus, setLoadStatus] = useState({ isLoading: true, isLoaded: false });
  // const [imageLoadStatus, setImageLoadStatus] = useState({ imageLoading: true });
  // const [expanded, setExpanded] = useState(false);
  // const [comments, setComments] = useState<any>(null);

  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);

  const { featureData, onClose } = props;

  const { type } = featureData;

  const getModel = () => {
    switch (type) {
      // case "stabla":
      //   return modelStabla;
      default:
        return modelData;
    }
  };

  // const refreshComments = () => {
  //   const dc = new dataController(modelComments, `data/poi/${record.id}/comments`)
  //   if (record) {
  //     dc.GetData().then((resp) => {
  //       if(resp && resp.data) {
  //         setComments(resp.data)
  //       } else {
  //         setComments(null)
  //       }
  //     })
  //   }
  // }

  // useEffect(() => {
  //   refreshComments()
  // }, [record])

  const model = getModel();

  const dc = new dataController(model);

  const faIconClass = "fas fa-info ";

  // const handleRefresh = function () {
  //   setRefreshCounter(refreshCounter + 1);

  //   setLoadStatus({ isLoading: true, isLoaded: false });
  // };

  // const handleDetailCollapse = function () {
  //   setExpanded(!expanded);
  // };

  // const handleCommentDialogClose = (result: any) => {
  //   if (result.dataChanged) {
  //     refreshComments()
  //   }
  // };

  // const handleAddComment = () => {
  //   const dc = new dataController(modelComments, `data/poi/${record.id}/comments`)

  //   const defaultAuthor = userContext && userContext.fullName && userContext?.userType != 2 ? userContext.fullName : null

  //   const defaultValues = {
  //     autor: defaultAuthor,
  //   }
    
  //   dialogContext.showDialog(CommentDialog, {
  //     dc: dc,
  //     mode: "insert",
  //     form: "default",
  //     defaultValues: defaultValues,
  //     dataId: record.id,
  //     onClose: handleCommentDialogClose
  //   });
  // };

  // const handleDeleteComment = (evt: any, comment: any) => {
  //   const dc = new dataController(modelComments, `data/poi/${record.id}/comments`)

  //   dc.DeleteRecord(comment.id)
  //   .then((result) => {
  //     refreshComments()
  //   })
  // }

  const handleOcijeni = () => {
    const dcGrading = new dataController(modelGrading , `data/poi/${record.id}/grade`);
    dialogContext.showDialog(GradingDialog, {
     type: type,
     dc: dcGrading,
     mode: "insert",
     form: "default",
     iconClass: faIconClass,
     onClose: (result:any) => handleDataDialogClose(result, record.id as number)
   });
 }

 const handleDataDialogClose = (dialogResult: any, recordId: number) => {
  if (dialogResult.dataChanged) {
    // dataService.getData(recordId).then((result) => {
    //   if (result.success) {
    //     setRecord(result.data);
    //   }
    // });
  }
};

  useEffect(() => {
    if (featureData) {
      if (featureData.record) {
        setRecord(featureData.record);
      } else if (featureData.feature) {
        setRecord(featureData.feature.properties);
      }
    }
    setRecord;
  }, [featureData]);

  const form = "infoCard";
  const title = record.naziv ? record.naziv as string : type ? t("layer." + type) : "Info";

  return (
    <Fragment>
      <MapCard
        sx={{
          backgroundColor: "background.default",
          minWidth: "200px"
        }}
      >
        <MapCardHeader
          title={title}
          subheader={t("common.id") + ": " + record.id}
          subheaderTypographyProps={{ color: "initial" }}
          avatar={<MapCardAvatar ariaLabel="info" icon={<i className={faIconClass}></i>} />}
          action={<MapCardHeaderButtonClose onClick={onClose} />}
        />
        <MapCardContent>
          <GridContainer spacing={0}>
            <GridItem xs={12} container>
              {/* <Form dc={dc} mode="view" form={form} record={record}> */}
                <GridContainer spacing={0}>
                <FormContent
                  // dc={dc}
                  // form={form}
                  fields={dc.getFormFields(form)}
                  fieldNames={dc.getFormFieldsNames(form)}
                  columns={1}
                  mode="simpleView"
                  record={record}
                />
                </GridContainer>
              {/* </Form> */}
            </GridItem>
            {/* <GridItem xs={6}>Image Placeholder</GridItem> */}
          </GridContainer>
        </MapCardContent>
        <MapCardActions>
          <MapCardActionButton onClick={handleOcijeni} startIcon={<StarOutlineIcon/>}>
            {t("buttons.ocijeni")}
          </MapCardActionButton>
          {/* <MapCardActionButton
              startIcon={<CommentIcon />}
              endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              onClick={handleDetailCollapse}
              aria-expanded={expanded}
              aria-label="expand"
              disabled={comments === null}
              stickyRight={true}
            >
              {t("buttons.comments_expand")}
            </MapCardActionButton> */}
        </MapCardActions>
        {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
          {comments && expanded ? (
            <MapCardContent>
              <GridContainer spacing={0} style={{ display: "flex", flexDirection: "column" }}>
                {comments.map((comment: any, index: any) => {
                  return (
                    <Accordion key={"accordion-" + index} style={{ width: "100%" }}>
                      <AccordionSummary
                        key={"accordion-" + index}
                        expandIcon={<ExpandMoreIcon />}
                        id={"panel-" + index}
                        aria-controls="filter-time-content"
                      >
                        <Typography variant="caption">
                          <b>{t("comment.biljeska")}</b>: {comment.biljeska}
                        </Typography>
                        <Typography variant="caption">
                          <b>{t("comment.autor")}</b>: {comment.autor}
                        </Typography>
                      </AccordionSummary>
                      <Divider />
                      <AccordionActions>
                        <Button
                          size="small"
                          startIcon={<DeleteIcon />}
                          onClick={(evt) => handleDeleteComment(evt, comment)}
                        >
                          {t("buttons.delete")}
                        </Button>
                      </AccordionActions>
                    </Accordion>
                  )
                })}

              </GridContainer>
            </MapCardContent>
          ) : null}
        </Collapse> */}
      </MapCard>
    </Fragment>
  );
}

export default GSInfoCard;
