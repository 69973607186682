import * as React from "react";

//Material-UI Core Components
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";

//Types
import { IDialogToolbarMenuItem } from "@/@types/ui/Dialog";

const DialogToolbarMenuItem = React.forwardRef<HTMLLIElement, IDialogToolbarMenuItem>((props, ref) => {
  const { onClick, label, icon } = props;

  return (
    <MenuItem
      onClick={onClick}
      ref={ref}
      sx={{
        backgroundColor: "primary.main",
        "&:hover": {
          backgroundColor: "primary.dark"
        }
      }}
    >
      {icon ? <ListItemIcon style={{ minWidth: 32, color: "#fff" }}>{icon}</ListItemIcon> : null}
      {label ? (
        <Typography variant="button" style={{ color: "#fff" }}>
          {label}
        </Typography>
      ) : null}
    </MenuItem>
  );
});

export default DialogToolbarMenuItem;
