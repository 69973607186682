import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

// Material-UI Components
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

import { SelectFilterWrapperFn, PickerValue } from "@/@types/ui/Table";
import { Row } from "react-table";

//Types
import { IDataControllerSub } from "@/@types/lib/dataControllerSub";

export const SelectColumnFilter: SelectFilterWrapperFn =
  (setFilterLocalStorage, subModelsWithData, fieldItems) =>
  ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    const { t } = useTranslation();

    const handleChange = (evt: SelectChangeEvent) => {
      const value = evt.target.value;
      setFilterLocalStorage(id, value, setFilter);
    };

    const options = React.useMemo(() => {
      const opts = new Set();
      if (fieldItems) {
        fieldItems.labels.filter((label, l) => {
          const found_value = preFilteredRows.find((pfr: Row) => pfr.values[id] === fieldItems.values[l]);
          if (found_value !== undefined) {
            if (fieldItems.icons) {
              opts.add({
                color: "primary",
                icon: fieldItems.icons[l],
                value: fieldItems.values[l],
                label: label
              });
            } else {
              opts.add({
                value: fieldItems.values[l],
                label: label
              });
            }
          }
        });
        return Array.from(opts.values());
      } else if (subModelsWithData) {
        if (subModelsWithData[id]) {
          const dc: IDataControllerSub = subModelsWithData[id];
          dc.records.filter((pi) => {
            const found_value = preFilteredRows.find((pfr: Row) => {
              const pfrValue = pfr.values[id];
              if (Array.isArray(pfrValue)) {
                return pfrValue.indexOf(pi.value) >= 0;
              } else {
                return pfrValue === pi.value
              }
            });
            if (found_value !== undefined) {
              opts.add({
                value: pi.value,
                label: pi.label
              });

            }
          })
          return Array.from(opts.values());
        }
      }
      return [];
    }, [id, preFilteredRows]);

    // this doesnt really work cuz no submodels
    // const renderOpts = options.map(optId => {
    //   return {
    //     value: optId,
    //     label: subModelsWithData && subModelsWithData[id] ?
    //       t(subModelsWithData[id].find(r => r.value === optId))
    //       : optId
    //   }
    // })

    return (
      <Select
        value={filterValue ? String(filterValue.value) : undefined}
        onChange={handleChange}
        MenuProps={{
          variant: "menu"
        }}
        variant="outlined"
        defaultValue=""
        displayEmpty
        input={<OutlinedInput size="small" margin="dense"/>}
      >
        <MenuItem value="" >
          {t("common.all")}
        </MenuItem>
        {(options as Array<PickerValue>).map((option, i) => (
          <MenuItem key={i} value={option.value} >
            {option.icon ? (
              <Icon fontSize="small" style={{ color: option.color }}>
                {option.icon}
              </Icon>
            ) : null}
            {t(option.label)}
          </MenuItem>
        ))}
      </Select>
    );
  };
