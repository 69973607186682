import React from "react";
import { useTranslation } from "react-i18next";

import GISPage from "@/ui/AppPage/GISPage";
import AppPage from "@/ui/AppPage/AppPage";
import MainMap from "./MainMap";

function MapPage() {

  return (
    //<GISPage>
    <AppPage>
      <MainMap />
    </AppPage>
    //</GISPage>
  );
}

export default MapPage;
