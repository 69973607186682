import React, { Fragment, useState, useEffect, useContext, SyntheticEvent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

// import ModelTable from "ui/Table/ModelTable";
import ModelTable from "@/ui/Table/ModelTable";
import MapContext from "@/components/Map/MapContext";

import dataController from "@/lib/dataController";
import { IDataPane } from "@/@types/components/MapSidebarPanes";
import { DCRecord } from "@/@types/lib/dataController";

const DataPane: FunctionComponent<IDataPane> = (props) => {
  const { t } = useTranslation();

  const context = useContext(MapContext);

  const { model, tableName, viewName, proj4 } = props;

  const [records, setRecords] = useState<DCRecord[]>([]);
  const [ selectedRecords, setSelectedRecords] = useState<number[]>([]);

  const dc = new dataController(model);

  const handleSelectRecord = (selection: number) => {
    // getting string represantation of point coordinates
    const extent = String(records.find(val => val.id == selection)?.st_astext)
    // console.log("Extend before parse: ", extent)

    // parsing it to float array
    const arr = extent?.substring(6,30).split(" ").map( (x: string) => parseFloat(x))
    // console.log("Selection: ", selection)
    // console.log("Selcted Records: ", extent)

    // converting from HTRS96 to GK6
    const x = proj4('EPSG:3765', 'EPSG:3857', arr)

    // calculating extent
    const finalExtent = [ (x[0]-40), (x[1]-40), (x[0]+40), (x[1]+40)]
    // console.log("Final extent: ", finalExtent)

    context?.map?.getView().fit(finalExtent)
  }

  const refreshData = () => {
    dc.GetData().then((resp) => {
      if (resp.success) {
        if (Array.isArray(resp.data)) {
          setRecords(resp.data);
        } else {
          setRecords([]);
        }
      }
    });
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <Fragment>
      <ModelTable
              dc={dc}
              tableName={tableName}
              records={records}
              allowSelection="one"
              // allowAdd={true}
              // allowFilter={true}
              viewName={viewName} title={""} 
              allowRowAction={(action: string, id: number) => {
                console.log("DataPane records: ", records)
                console.log("DataPane selected records: ", selectedRecords)
                return true
              }} 
              onRowAction={(action: string, id: number) => {
                console.log("DataPane records: ", records)
                console.log("DataPane selected records: ", selectedRecords)
              } }        // sidebarpane={true}
        onRowClick={handleSelectRecord}
        // pagination={false}
        // headerButtonsType="icon"
      >
        {/* <TableButton variant="outlined" allow="one" onClick={handleEdit} startIcon={<EditIcon />} selection={[]}            
            // needRole={[Role.ADMINISTRATOR, Role.DATA_EDITOR]}
            >
          {t("buttons.edit")}
        </TableButton> */}
        {/* <TableButton variant="contained" allow="always" onClick={handleShowOnMap} startIcon={<RoomIcon />} selection={[]}>
          {t("buttons.mark_on_map")}
        </TableButton> */}
      </ModelTable>
    </Fragment>
  );
}

export default DataPane